.dynamic-tabs-container {
  font-family: var(--font-family-inter);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.dynamic-tabs-header {
  font-family: var(--font-family-inter);
  display: flex;
  flex-direction: row;
  background-color: var(--surface-primary);
  border-bottom: 3px solid var(--border-primary);
  width: 100%;
  justify-content: stretch;
  align-items: center;
  padding-top: var(--spacingx2);
}

.dynamic-tabs-button {
  font-family: var(--font-family-inter);
  flex: 1;
  border: none;
  background-color: transparent;
  padding: var(--spacingx2);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  cursor: pointer;
}

.dynamic-tabs-button.active {
  font-family: var(--font-family-inter);
  color: var(--content-active);
  font-weight: var(--font-weight-semibold);
  border-bottom: 3px solid var(--content-active);
}
