@media (max-width: 640px) {
  .filter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay */
    display: flex;
    height: 100%;
  }

  .filter-modal-content {
    border-radius: 10px;
    width: 100%;
    overflow-y: auto;
    z-index: 1001;
    height: 100%;
  }

  .filter-container-modal {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    height: 100%;
    background: var(--White, #fff);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .filter-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: var(--White, #fff);
    position: fixed;
    height: 60px;
    border-bottom: 2px solid var(--Light-Grey, #e3e3e3);
  }

  .filter-options-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 170px;
    bottom: 100px;
    overflow: hidden;
    height: auto;
    padding-top: 1px;
    border-width: 0px;
  }

  .filter-footer {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    border-top: 2px solid var(--Light-Grey, #e3e3e3);
    background: var(--White, #fff);
    padding-bottom: 20px;
    position: fixed;
    height: 60px;
    bottom: 0;
    width: 100%;
  }

  .filter-title-text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: x-large;
    color: #333;
    font-weight: 600;
  }

  /* Filter types */
  .filter-options-list {
    width: 40%;
    background-color: var(--Light-Grey, #f7f7f7);
    border-right: 2px solid var(--White, #fff);
  }

  .filter-option,
  .selected-filter {
    padding: 15px;
    background-color: var(--Light-Grey, #f7f7f7);
    border-width: 0px;
    width: 100%;
    height: 77px;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--Black, #161719);
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  .selected-filter {
    font-size: 1rem;
    font-weight: 600;
    color: #207a9c;
    background: var(--White, #fff);
    font-family: "Plus Jakarta Sans", sans-serif;
    border-width: 0px;
  }

  /* Category, sub cat types */
  .filter-options-list-options-container {
    width: 60%;
    overflow-y: auto;

    background: var(--White, #fff);
    gap: 0px;
  }

  /* CheckableListItem.css */
  .checkable-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    padding: 10px 15px;
    border: 0;
    cursor: pointer;
    height: 53px;
    margin: 0px;
  }

  .item-text {
    padding-left: 10px;
    font-size: 1rem;
    color: var(--Black, #161719);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .check-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .filter-footer-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 0;
    align-items: center;
  }

  /* Button Styles */
  .custom-button {
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 0;
  }
  .filter-left-button,
  .filter-right-button {
    height: 45px;
    width: 156px;
    border: none;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }

  .filter-left-button {
    background-color: white;
    border: 1px solid #ccc;
  }

  .filter-left-button-disabled {
    background-color: #f5f5f5;
  }

  .filter-left-button-text {
    color: #207a9c;
  }

  .filter-left-button-text-disabled {
    color: #ccc;
  }

  .filter-right-button {
    background-color: #207a9c;
    color: white;
  }

  .filter-right-button-text {
    font-size: 1.5rem;
    color: white;
  }

  .filter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay effect */
    z-index: 999;
  }
}

@media (min-width: 641px) {
  .filter-modal {
    background: var(--White, #fff);
    border: 1px solid var(--Light-Grey, #e3e3e3);
    width: 100%;
  }

  .filter-title-container {
    display: none;
  }
  .filter-options-list {
    display: none;
  }

  .filter-options-list-options-container {
    max-height: 300px;
    overflow-y: auto;
    background: var(--White, #fff);
    gap: 0px;
  }
  .filter-footer {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    border-top: 2px solid var(--Light-Grey, #e3e3e3);
    background: var(--White, #fff);
    padding-bottom: 20px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .filter-footer-buttons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0;
    align-items: center;
    width: 100%;
  }
  .custom-button {
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 0;
  }
  .filter-left-button,
  .filter-right-button {
    height: 45px;
    width: 156px;
    border: none;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }

  .filter-left-button {
    background-color: white;
    border: 1px solid #ccc;
  }

  .filter-left-button-disabled {
    background-color: #f5f5f5;
  }

  .filter-left-button-text {
    color: #207a9c;
  }

  .filter-left-button-text-disabled {
    color: #ccc;
  }

  .filter-right-button {
    background-color: #207a9c;
    color: white;
  }

  .filter-right-button-text {
    font-size: 1.5rem;
    color: white;
  }
  .checkable-item {
    display: flex;
    align-items: center;
    justify-self: start;
    padding: 10px 15px;
    border: 0;
    cursor: pointer;
    height: 53px;
    margin: 0px;
  }
  .item-text {
    font-size: 1rem;
    color: var(--Black, #161719);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .check-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
