/* Dynamic tab about section */
/* About Section */
.storefront-about-section {
  display: flex;
  flex-direction: column;
  background-color: var(--surface-primary);
  font-family: var(--font-family-inter);
  gap: var(--spacingx4);
  padding-top: var(--spacingx4);
}

.storefront-about-section-rating-card {
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
}

.storefront-about-section-rating-card-heading {
  display: flex;
  align-items: center;
  justify-content: start;
}

.storefront-about-section-rating-card-heading-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx1);
}

.storefront-about-section-rating-card-heading-rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.storefront-about-section-rating-card-heading-rating-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
}

.storefront-about-section-rating-card-heading-rating-separator {
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
  padding-left: var(--spacingx1);
  padding-right: var(--spacingx1);
}

.storefront-about-section-rating-summary {
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
}

.storefront-about-section-reviews-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* Keep items in a single row (no wrapping) */
  overflow-x: auto; /* Horizontal scroll if needed */
  gap: var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
}
.storefront-about-section-show-all-reviews-button-container {
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}
.storefront-about-section-show-all-reviews-button {
  font-family: var(--font-family-inter);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  font-style: normal;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-action);
  box-shadow: none;
  min-height: 44px;
}

.storefront-about-section-store-info-card-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
}
.storefront-about-section-store-info-card {
  width: 100%;
}

.storefront-about-section-flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2 * var(--spacingx3));
  padding: var(--spacingx4) var(--spacingx3);
}

.storefront-about-section-store-info-card-body-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-basis: 100%;
  gap: var(--spacingx3);
  flex: 1;
}

.storefront-about-section-flex-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.storefront-about-section-flex-item-text {
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-medium);
}

.storefront-about-section-store-expand-svg {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.storefront-about-section-store-info-card-body-content {
  background: var(--surface-secondary);
  padding: var(--spacingx3) var(--spacingx4);
  gap: var(--spacingx3);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: calc(100% - 2 * var(--spacingx4));
}

.storefront-about-section-store-info-card-body-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx2);
}

.storefront-about-section-store-info-card-body-content-item-title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.storefront-about-section-store-info-card-body-content-item-title-text {
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--content-primary);
  line-height: var(--line-height-small);
}

.storefront-about-section-return-policy-description {
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--content-primary);
  line-height: var(--line-height-small);
  padding: 0;
  margin: 0;
}

.storefront-about-section-return-policy-list {
  list-style-type: disc;
  margin-left: var(--spacingx5);
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.storefront-about-section-return-policy-list li {
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-small);
}

.storefront-about-section-return-policy-note {
  font-size: 14px;
  color: #666;
  margin-top: 12px;
  margin-left: 10px;
}

.storefront-about-section-svg-rotate {
  transform: rotate(180deg);
}

.storefront-about-section-return-expand-svg {
  /* margin-left: 60%; */
  display: inline;
  float: right;
  margin-right: 13px;
}

.storefront-about-section-review-card {
  display: flex;
  padding: 8px;
  flex-direction: row;
}

.storefront-about-section-store-profile {
  /* border:1px solid red; */
  display: inline-flex;
  width: 60%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

.storefront-about-section-store-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-bottom: 10px; */
}

.storefront-about-section-store-name {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1.5%;
  font-weight: 600;
}

.storefront-about-section-store-stats {
  display: inline-flex;
  flex-direction: column;
  width: 45%;
  padding-left: 20px;
  /* justify-content: space-between; */
  /* border:1px solid red; */
}

.storefront-about-section-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  margin-left: 6px;
  width: 70%;
}

.storefront-about-section-star {
  display: inline-block;
  position: relative;
  top: 5px; /* Moves the element down */
  right: 3px;
}

.store-front-bottom-line {
  border-bottom: 1px solid #e5e7eb;
}

.storefront-about-section-stat-value {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.storefront-about-section-stat-label {
  font-size: 14px;
  color: #666;
}
