/* Store Info */
.storefront-store-info {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-inter);
  padding: var(--spacingx4) var(--spacingx3);
  margin-bottom: var(--spacingx1);
  background-color: var(--surface-primary);
  gap: var(--spacingx3);
}

.storefront-store-info-store-header {
  display: flex;
  align-items: center;
}

.storefront-store-info-store-avatar {
  width: 64px;
  height: 64px;
  border-radius: var(--radius-round);
  border: 1px solid var(--border-primary);
  object-fit: cover;
  margin-right: var(--spacingx4);
}

.storefront-store-info-store-details {
  display: flex;
  align-items: start;
  flex: 1;
  flex-direction: column;
  gap: var(--spacingx2);

  padding-top: var(--spacingx1);
  padding-bottom: var(--spacingx1);
}

.storefront-store-info-store-name {
  width: 100%;
  text-align: center;
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-large);
  font-style: normal;
  color: var(--content-primary);
}

/* Store-info-Store Stats */
.storefront-store-info-store-stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--spacingx2);
}

.storefront-store-info-store-stats-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--spacingx1) var(--spacingx2);
  gap: var(--spacingx1);
  border-radius: var(--radius-small);
  background: var(--surface-secondary);
  height: fit-content;
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-style: normal;
  color: var(--content-primary);
}

.storefront-store-info-share-button {
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
}

.storefront-store-info-store-description {
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  font-style: normal;
  color: var(--content-primary);
  font-family: var(--font-family-inter);

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.storefront-store-info-store-description.full {
  /* When full text is displayed, remove truncation */
  line-clamp: unset;
  -webkit-line-clamp: unset;
  overflow: visible;
}

.read-more-button {
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-style: normal;
  color: var(--content-primary);
  background: none;
  border: none;
  cursor: pointer;
  background-color: var(--white);
  border: none;
  box-shadow: none;
  padding: 0;
}

.storefront-store-info-store-buttons {
  display: flex;
  flex-direction: row;
  gap: var(--spacingx3);
  width: 100%;
}

.storefront-store-info-store-buttons-follow {
  font-family: var(--font-family-inter);
  color: var(--content-inverted);
  width: 100%;
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-style: normal;
  background-color: var(--surface-inverted);
  box-shadow: none;
  min-height: 32px;
  border: 1px solid var(--surface-inverted);
}

.storefront-store-info-store-buttons-follow:hover {
  background: var(--surface-inverted-variant);
  color: var(--content-inverted);
}

.storefront-store-info-store-buttons-unfollow {
  font-family: var(--font-family-inter);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-style: normal;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-action);
  box-shadow: none;
  min-height: 32px;
}

.storefront-store-info-store-buttons-unfollow:hover {
  background: var(--surface-secondary-pressed);
}

.storefront-store-info-store-buttons-message {
  font-family: var(--font-family-inter);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-style: normal;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-action);
  box-shadow: none;
  min-height: 32px;
}
