.all-reviews-page {
  padding: var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
  font-family: var(--font-family-inter);
}
.all-reviews-page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: var(--spacingx2);
}

.all-reviews-page-header-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx2);
}

.all-reviews-page-header-rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.all-reviews-page-header-rating-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  font-size: var(--label-xlarge);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-xlarge);
}

.all-reviews-page-header-rating-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--label-xlarge);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-xlarge);
  padding-left: var(--spacingx1);
  padding-right: var(--spacingx1);
}

.all-reviews-page-header-rating-summary {
  font-size: var(--label-xlarge);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-xlarge);
}

.all-reviews-page-avg-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.all-reviews-page-avg-rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacingx4) var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}

.all-reviews-page-avg-rating-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex: 1;
}
.all-reviews-page-avg-rating-left-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  color: var(--content-primary);
  line-height: var(--line-height-medium);
}

.all-reviews-page-avg-rating-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacingx2);
}

.all-reviews-page-avg-rating-right-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-medium);
}

.all-reviews-page-reviews-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: var(--spacingx3);
  padding-top: var(--spacingx3);
  padding-bottom: var(--spacingx3);
}

.all-review-page-about-section-reviews-card {
  font-family: var(--font-family-inter);
  border-radius: var(--radius-large);
  border: 1px solid var(--border-primary);
  background: var(--surface-primary);
  padding: var(--spacingx3);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: var(--spacingx6);
  width: calc(100% - 2 * var(--spacingx3));
}

.all-review-page-about-section-reviews-card-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  min-width: 176px;
  gap: var(--spacingx2);
}
.all-review-page-about-section-reviews-card-heading-rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx0-5);
}
.all-review-page-about-section-reviews-card-heading-rating-text {
  font-family: var(--font-family-inter);

  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--text-primary);
  line-height: var(--line-height-small);
}

.all-review-page-about-section-reviews-card-reviewer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx2);
}

.all-review-page-about-section-reviews-card-reviewer-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-review-page-about-section-reviews-card-reviewer-avatar {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-round);
  border: 1px solid var(--border-primary);
  object-fit: cover;
}

.all-review-page-about-section-reviews-card-reviewer-name-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.all-review-page-about-section-reviews-card-reviewer-name {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  line-height: var(--line-height-small);
}

.all-review-page-about-section-reviews-card-review-date {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--text-secondary);
  line-height: var(--line-height-small);
}
