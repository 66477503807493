.mobile-live-stream-container {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.enter-stream-button-container {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  max-width: 1000px;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: white;
}

.enter-stream-button {
  cursor: pointer;
  background: #ffffff;
  padding: 14px 20px 14px 20px;
  margin: 1rem;
  border: 1px;
  border-radius: 4px;
  background: #1e799c;
  width: 100%;
}

.enter-stream-button-text {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .enter-stream-button-container {
    left: 0;
  }
}
