/* Skeleton Loader Styles */
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.skeleton-card {
  background-color: white;
  margin-bottom: 4%;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.05));
  /* padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%; */
  padding: 3%;
}

.orders-page-card-skeleton-img-top {
  /* height: 100%; */
  width: 25px;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.orders-page-card-skeleton-text {
  width: 9rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.orders-page-quantity-box-skeleton {
  border-radius: 8px;
  height: 0.2rem;
  width: 3rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.orders-page-card-header-skeleton {
  display: flex;
  width: full;
  height: 3rem;
}

.orders-page-header-text-skeleton {
  margin-left: 20px;
}

.orders-page-button-container-skeleton {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.orders-page-quantity-container-skeleton {
  margin-right: 2%;
}
