.hls-container {
  width: 100%;
  height: 90%;
  margin-top: 1%;
  position: relative;
  z-index: -1;
  padding: 3%;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 1rem;
  border: 1px solid #e5e5e8;
}

.hls-video {
  width: 100%;
  min-height: 100%;
  border-radius: 1rem;
  object-fit: fill;
}

@media only screen and (max-width: 1000px) {
  .hls-container {
    width: 100%;
    height: 100%;
    padding: 0%;
    filter: none;
    border-radius: 0rem;
    border: none;
    margin: 0 auto;
  }
  .hls-video {
    width: 100%;
    min-height: 100%;
    border-radius: 0px;
  }
}
