.remind-me-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.remind-me-popup {
  position: absolute;
  width: 300px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
  opacity: 1;
}

.remind-me-popup {
  /* Other styles */
  transition: transform 0.3s ease-in-out; /* Apply sliding transition */
  transform: translateX(0);
}

.remind-me-popup.slide-left {
  transform: translateX(
    -100%
  ); /* Move content to the left when reminder is sent */
}

.popup-body,
.popup-footer {
  transition: opacity 0.3s ease-in-out; /* Smooth transition for body and footer visibility */
}

.popup-body.hidden,
.popup-footer.hidden {
  opacity: 0; /* Make elements fade out */
}

.popup-header {
  margin-top: 16px;
  text-align: center;
  margin-bottom: 16px;
  font-family: Plus Jakarta Sans;
}

.popup-header p {
  font-size: 18px;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
}
.phone-input {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  font-family: Plus Jakarta Sans;
}

.country-code {
  font-family: Plus Jakarta Sans;
  border: none;
  border-radius: 5px;
}

.phone-number {
  flex: 1 1;
  padding: 10px;
  font-family: Plus Jakarta Sans;
  border: none;
  border-radius: 5px;
}

.popup-cancel-button,
.popup-submit-button {
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  background-color: white;
  color: #1e799c;
  font-weight: 600;
  margin: 0;
  font-family: Plus Jakarta Sans;
}
.popup-cancel-button {
  width: 30%;
}

.popup-submit-button {
  margin: 0;
  width: 68%;
  margin-left: 4px;
  background-color: white;
}

.popup-whatsapp-text {
  font-size: 11px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
  color: #6b7280;
}

.popup-footer {
  margin-top: 15%;
}

.popup-whatsapp-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
