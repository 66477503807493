.storefront-store-collections {
  font-family: var(--font-family-inter);
  padding: var(--spacingx3);
  display: flex;
  flex-direction: column;
  gap: var(--spacingx3);
  margin-bottom: var(--spacingx1);
  background-color: var(--surface-primary);
}

.storefront-store-collections-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.storefront-store-collections-header-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  font-style: normal;
  line-height: var(--line-height-small);
}

.storefront-store-collections-header-view-all {
  font-family: var(--font-family-inter);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: self-start;
  gap: var(--spacingx1);
  color: var(--content-action);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  font-style: normal;
}

.storefront-store-collections-header-view-all-text {
  display: flex;
  align-items: center;
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  font-style: normal;
  line-height: var(--line-height-small);
}

.storefront-store-collections-list {
  display: flex;
  flex-wrap: nowrap; /* Keep items in a single row (no wrapping) */
  overflow-x: auto; /* Horizontal scroll if needed */
  gap: var(--spacingx3);
  width: 100%;

  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
}

.storefront-store-collections-list-item {
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-primary);
  width: 122px;
  height: 122px;
  position: relative;
  box-shadow: none;
}

.storefront-store-collections-list-item:hover {
  border: 1px solid var(--black);
  cursor: pointer;
  box-shadow: none;
}

.storefront-store-collections-list-item-image {
  border-radius: var(--radius-medium);

  width: 122px;
  height: 122px;
  box-shadow: none;
}

.storefront-store-collections-list-item-name {
  font-family: var(--font-family-inter);
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  font-style: normal;
  line-height: var(--line-height-small);
  color: var(--white);
  text-align: center;
  padding: var(--spacingx2);
  width: calc(100% - 2 * var(--spacingx2));
}

.storefront-store-collections-list-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}
