.order-details-product-card-container {
  margin: 0 auto;
  background-color: white;
  padding: 5%;
  width: 90%;
  max-width: 1000px;
}

.order-details-product-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.order-details-product-left-container {
  width: 25%;
  cursor: pointer;
}

.order-details-product-right-container {
  padding-left: 5%;
  width: 75%;
}

.order-details-product-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.order-details-product-name {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-details-product-description {
  color: #757575;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-details-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.order-details-quantity-container {
  display: flex;
  align-items: center;
  margin-right: 5%;
}

.order-details-dropdown-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0.25rem;
  text-align: center;
  flex-direction: column;
}

.dropdown-button-text-order-details {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  text-align: center;
}

.order-details-variant-container {
  margin: 3px;
}

.order-details-variant-container.hidden {
  display: none;
}
