.loginMobile-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* padding: 5%; */
  align-items: center;
  justify-content: center;
}

.enter-number-heading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
  margin-top: 1rem;
}
.enter-number-subheading {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: grey;
}

.loginMobile-container h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.loginMobile-container input {
  width: 85%;
  height: 2.2rem;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 2%;
  font-size: 16px;
  padding-left: 5%;
  font-family: Plus Jakarta Sans;
}

.loginMobile-container button {
  width: 90%;
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  background: #f5f2fc;
  background-color: rgb(245, 242, 252);
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.loginMobile-container button:hover {
  background: #1e799c;
  color: white;
}

.loginMobile-container button:hover {
  background: #1e799c;
  color: white;
}
.countryCode {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}

.inputWithCountryCode input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}

.footer-text {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 400;
  color: #838799;
}

.term-and-condition-text {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  color: #1e799c;
  cursor: pointer;
  position: relative;
}

.footer-text-container {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  color: #141519;
  text-align: center;
  width: 90%;
}

.term-and-condition-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #141519;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  background: linear-gradient(#ffffff, #216987);
  /* height: 50%; */
}

.carousel-heading {
  font-weight: bold;
  font-size: 1rem;
  font-family: Plus Jakarta Sans;
}

.carousel-stream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.carousel-image-wrapper {
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
  /*width: 100%;*/
  height: 100%;
  overflow: hidden;
  aspect-ratio: 10/16;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.carousel-image-title {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  padding: 1rem 0.5rem 1rem 0.5rem;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
}

.carousel-stream-timing {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
  font-family: Plus Jakarta Sans;
}

@media only screen and (max-width: 1000px) {
  .chat-box {
    width: 95%;
  }
}
