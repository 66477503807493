.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000000000;
  overflow: auto;
}

.image-modal-container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  max-width: 90vw;
  max-height: 90vh;
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-modal-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: white;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.image-modal-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 0.5rem;
}

@media (max-width: 768px) {
  .image-modal-container {
    max-width: 95vw;
    padding: 1rem;
  }
}
