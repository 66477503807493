/* Overlay styling */
.storefront-review-overlay-review-overlay {
  position: fixed;
  top: 40%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: absolute;
}

.storefront-review-overlay-review-overlay .overlay-header {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px 8px 0 0;
}

.storefront-review-overlay-overlay-title {
  font-size: 20px;
  font-weight: bold;
}

.storefront-review-overlay-close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.storefront-review-overlay-review-list {
  width: 90%;
  background-color: #ffffff;
  padding: 16px;
  padding-top: 0%;
  max-height: 80%;
  overflow-y: scroll;
  border-radius: 0 0 8px 8px;
}

.storefront-review-overlay-review-item {
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
}

.storefront-review-overlay-review-header {
  align-items: center;
  font-size: 14px;
  color: #666;
}

.storefront-review-overlay-stars {
  color: #ffc107;
  flex: 1;
  display: flex;
}

.storefront-review-overlay-stars .storefront-review-overlay-review-date {
  color: #6b7280;
  font-size: 12px;
  padding: 3px;
}

.storefront-review-overlay-review-date .storefront-review-overlay-verified {
  color: #0077b6;
  font-weight: bold;
}

.storefront-review-overlay-review-text {
  font-size: 14px;
  color: #333;
  margin: 8px 0;
}

.storefront-review-overlay-review-author {
  font-size: 14px;
  color: #666;
}

/* Show the overlay */
.storefront-review-overlay-review-overlay.show {
  display: flex;
}

.storefront-review-overlay-exit-stream {
  width: 90px;
  height: 30px;
  padding: 8px 12px 8px 12px;
  gap: 6px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #d9342b;
  box-shadow: 0px 1px 2px 0px #1018280a;
  font-family: Inter;
  font-size: 11px;
  color: #d9342b;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-decoration-color: #1e799c;
  cursor: pointer;
  background: transparent;
  z-index: 10;
}

.storefront-review-overlay-exit-stream-content-container {
  padding: 0% 0% 0% 0%;
}

.storefront-review-overlay-exit-stream-yes-button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: white;
  color: #dc3545;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #dc3545;
}

.storefront-review-overlay-exit-stream-no-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.storefront-review-overlay-chat-wrapper-container {
  bottom: 0;
  position: absolute;
  width: 100%;
  margin-bottom: 5%;
}

.storefront-review-overlay-stream-video-action-container {
  top: 40%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 1000;
}

.storefront-review-overlay-stream-video-action-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.storefront-review-overlay-review-photos {
  display: flex;
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 12px;
  flex-direction: column;
}

.storefront-review-overlay-review-photo {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}
.storefront-review-overlay-photo-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.storefront-review-overlay-more-photos {
  width: 39px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}
