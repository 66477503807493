.select-address-v2-container {
  font-family: Plus Jakarta Sans;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.selectAddressV2-topStrip {
  padding: 16px 16px 16px 21px;
  font-family: Plus Jakarta Sans;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  background: #efefef;
  position: relative;
}

.selectAddressV2-savedAddressText {
  color: #686b77;
  max-width: 55%;
}

.selectAddressV2-addressContainer {
  font-family: Plus Jakarta Sans;
  position: relative;
  /* padding: 16px; */
  margin-bottom: 8px;
  cursor: pointer;
  background-color: #efefef;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 12%;
}

.selectAddressV2-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.selectAddressV2-name {
  font-weight: 700;
  font-size: 14px;
  color: #282c3f;
  text-transform: capitalize;
  word-break: break-all;
}
.selectAddressV2-address-type {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 20px;
  color: #03a685;
  font-weight: 700;
  border: 1px solid #03a685;
  margin-left: 8px;
  line-height: 12px;
}

.selectAddressV2-details-address {
  margin-top: 4px;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  color: #737a78;
  flex-direction: column;
  word-break: break-all;
  font-family: Plus Jakarta Sans;
}
.selectAddressV2-mobile {
  margin-top: 5px;
}

.selectAddressV2-base-button {
  background-color: #e9e9eb;
  color: #282c3f;
  border-radius: 4px;
  padding: 13px 16px;
  font-size: 12px;
  font-weight: 700;
  /* min-height: 32px; */
  line-height: 1px;
  width: fit-content;
  margin-top: 6px;
  letter-spacing: 0;
}

.selectAddressV2-action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-top: 3%; */
  width: 100%;
  column-gap: 4%;
}

.selectAddressV2-negative-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.85rem;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #1e799c;
}

.selectAddressV2-address-edit {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 20px;
  color: #03a685;
  font-weight: 700;
  border: 1px solid #03a685;
  margin-left: 8px;
  line-height: 12px;
  background-color: #fff;
  color: #282c3f;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 700;
  padding-top: 11px;
  padding-bottom: 13px;
  line-height: 1px;
  letter-spacing: 0;
  border: 1px solid #282c3f;
}

.selectAddressV2 {
  transition: all 0.3s ease;
}

.selectAddressV2.collapsed {
  height: 60px; /* or whatever height you want for collapsed addresses */
  overflow: hidden;
}
