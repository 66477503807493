/* SortBy.css */
@media (max-width: 640px) {
  .sort-by-modal {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .sort-by-content {
    background-color: white;
    border-radius: 10px;
    margin-top: 60%;
    width: 100%;
    height: 60%;
    z-index: 1001;
  }

  .sort-by-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5%;
    border-bottom: 2px solid var(--Light-Grey, #e3e3e3);
  }

  .sort-by-title {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: x-large;
    color: #333;
    font-weight: 600;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }

  .sort-by-options-container {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    /* padding-left: 10%;
  padding-right: 10%; */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 200px;
  }

  /* CheckableListItem.css */
  .sortby-checkable-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 0;
    cursor: pointer;
    height: 53px;
    margin: 0px;
  }

  .sortby-checkable-item :hover {
    background: var(--Light-Grey, #e3e3e3);
  }

  .sortby-item-text {
    font-size: 1rem;
  }

  .sortby-radio {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .sortby-footer {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    border-top: 2px solid var(--Light-Grey, #e3e3e3);
    background: var(--White, #fff);
    padding-bottom: 20px;
    position: fixed;
    height: 60px;
    bottom: 0;
    width: 100%;
  }

  .sortby-footer-buttons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0;
    align-items: center;
    width: 100%;
  }
  .custom-button {
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 0;
  }
  .sortby-left-button,
  .sortby-right-button {
    height: 45px;
    width: 156px;
    border: none;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }

  .sortby-left-button {
    background-color: white;
    border: 1px solid #ccc;
  }

  .sortby-left-button-disabled {
    background-color: #f5f5f5;
  }

  .sortby-left-button-text {
    color: #1e799c;
  }

  .sortby-left-button-text-disabled {
    color: #ccc;
  }

  .sortby-right-button {
    background-color: #1e799c;
    color: white;
  }

  .sortby-right-button-text {
    font-size: 1.5rem;
    color: white;
  }
}

@media only screen and (min-width: 641px) {
  .sort-by-modal {
    background: white;
    width: 100%;
    border-radius: 8px;
    border-width: 1px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    z-index: 1000;
  }

  /* CheckableListItem.css */
  .sortby-checkable-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-width: 0px;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    height: 53px;
    z-index: 1000;
  }

  .sortby-checkable-item:hover {
    background: var(--Light-Grey, #e3e3e3);
  }

  .sort-by-content {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    height: 90%;
    z-index: 1000;
  }
  .sort-by-header {
    display: none;
  }
  .sortby-item-text {
    font-size: 1rem;
    color: var(--Black, #161719);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .sortby-radio {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .sortby-footer {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    border-top: 2px solid var(--Light-Grey, #e3e3e3);
    background: var(--White, #fff);
    padding-bottom: 20px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .sortby-footer-buttons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0;
    align-items: center;
    width: 100%;
  }
  .custom-button {
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 0;
  }
  .sortby-left-button,
  .sortby-right-button {
    height: 45px;
    width: 156px;
    border: none;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }

  .sortby-left-button {
    background-color: white;
    border: 1px solid #ccc;
  }

  .sortby-left-button-disabled {
    background-color: #f5f5f5;
  }

  .sortby-left-button-text {
    color: #1e799c;
  }

  .sortby-left-button-text-disabled {
    color: #ccc;
  }

  .sortby-right-button {
    background-color: #1e799c;
    color: white;
  }

  .sortby-right-button-text {
    font-size: 1.5rem;
    color: white;
  }
}
