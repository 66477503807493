.storefront-product-section-product-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--surface-secondary);
  font-family: var(--font-family-primary);
  /* border:2px solid green; */
  gap: var(--spacingx3);
}

.storefront-product-section-product-page-header {
  font-family: var(--font-family-inter);
  font-size: var(--title-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
  background-color: var(--surface-primary);
  width: calc(100% - 2 * var(--spacingx3));
  padding: var(--spacingx2) var(--spacingx3);
}

.storefront-product-section-product-page-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--spacingx2);
  border-radius: var(--radius-large);
  background: var(--surface-tertiary);
}

.storefront-product-section-product-page-search-bar-icon {
  padding-left: var(--spacingx3);
  padding-top: var(--spacingx3);
  padding-bottom: var(--spacingx3);
}

.storefront-product-section-product-page-search-bar-input {
  height: 100%;
  width: 100%;
  padding-right: var(--spacingx3);
  padding-top: var(--spacingx3);
  padding-bottom: var(--spacingx3);
  border: none;
  background: transparent;
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.storefront-product-section-product-page-search-bar-input::placeholder {
  color: var(--content-secondary);
}

.storefront-product-section-product-page-search-bar-input:focus {
  outline: none;
}

/* .storefront-product-section-product-page-heading {
  padding: 18px;
  text-align: center;
}

.storefront-product-section-product-page-subcategories {
  padding: 10px;
}

.storefront-product-section-categories {
  padding: 10px;
  display: flex;
  gap: 10px;
  overflow-x: auto;
} */

/* .storefront-product-section-category-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
} */

.storefront-product-section-product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacingx3);
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
}

.storefront-product-section-product-card {
  border-radius: var(--radius-medium);
  overflow: hidden;
}

.storefront-product-section-product-image-box {
  width: 100%;
  height: 216px;
  object-fit: cover;
  position: relative;
}

.storefront-product-section-product-image {
  width: 100%;
  height: 216px;
  object-fit: cover;
  border-top-left-radius: var(--radius-medium);
  border-top-right-radius: var(--radius-medium);
}

.storefront-product-section-info {
  padding: var(--spacingx1);
}

.storefront-product-section-name {
  font-family: var(--font-family-inter);
  color: var(--content-primary);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
}

.storefront-product-section-description {
  font-family: var(--font-family-inter);
  color: var(--content-active);
  overflow: hidden;
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: var(--spacingx2);
}

.storefront-product-section-price {
  font-size: var(--label-large);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-large);
  font-family: var(--font-family-inter);
}

.storefront-product-section-original-price {
  text-decoration: line-through;
  color: var(--content-inactive);
  font-weight: var(--font-weight-regular);
  font-size: var(--label-small);
  font-family: var(--font-family-inter);
}

.storefront-product-section-rating {
  color: #ffa500;
}

/* .storefront-product-section-product-rating{

  } */

.storefront-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin: 20px 0;
}

.storefront-pagination-arrow {
  background: none;
  border: none;
  font-size: 20px;
  color: #6b7280; /* Neutral gray */
  cursor: pointer;
  transition: color 0.3s ease;
}

.storefront-pagination-arrow:disabled {
  color: #d1d5db; /* Light gray */
  cursor: not-allowed;
}

.storefront-pagination-numbers {
  display: flex;
  align-items: center;
  gap: 10px;
}

.storefront-pagination-label {
  font-size: 16px;
  color: #6b7280;
}

.storefront-pagination-number {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f9fafb; /* Light background */
  color: #6b7280; /* Neutral gray */
  font-size: 16px;
  transition: all 0.3s ease;
}

.storefront-pagination-number:hover {
  background-color: #e5e7eb; /* Hover background */
}

.storefront-pagination-number.active {
  background-color: #e0f2fe; /* Active background */
  color: #0284c7; /* Active blue */
  font-weight: bold;
  border: 2px solid #0284c7;
}

.storefront-product-section-tag-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.storefront-product-section-tag-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacingx2);
  background: var(--surface-status);
  padding: var(--spacingx1);
  border-top-right-radius: var(--radius-small);
  border-bottom-right-radius: var(--radius-small);
  width: fit-content;
  text-align: left;
}

.storefront-product-section-tag-cover-text {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
}

.storefront-product-section-rating {
  position: absolute;
  bottom: 4px;
  left: 4px;
  background-color: var(--surface-action1);
  padding: var(--spacingx1) var(--spacingx2);
  border-radius: var(--radius-small);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacingx1);
}

.storefront-product-section-rating-star {
  display: flex;
  align-items: center;
  justify-content: center;
}

.storefront-product-section-rating-count {
  display: flex;
  align-items: center;
  justify-content: center;
}

.storefront-product-section-rating-text {
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  font-family: var(--font-family-inter);
  color: var(--white);
}
