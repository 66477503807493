.mobile-product-details {
  text-align: left;
  width: 100%;
}
.mobile-product-name {
  color: #222026;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.mobile-product-description {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seller-info {
  display: flex;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  height: 32px;
  margin-right: 10px;
}

.seller-name {
  color: #38353f;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}

.mobile-product-details-status-title {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}

.mobile-product-details-container {
  border-radius: 1.5rem;
  background: #3897f1;
  width: fit-content;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin-top: 3%;
}

.mobile-product-details-seller-container {
  margin-top: 4%;
  margin-bottom: 4%;
}

.mobile-product-stream-time {
  color: #38353f;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}
