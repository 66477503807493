.order-details-page-card-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid #e0e0e0;
  margin-bottom: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.order-details-page-card-heading-container {
  padding: 0 16px;
}

.order-details-page-card-heading-text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: larger;
  font-weight: 600;
}

.order-details-page-card-body-container {
  padding: 0 16px;
}

.order-details-page-card-row-container {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.order-details-page-card-row-left-container {
  flex: 1;
}

.order-details-page-card-row-right-container {
  /* flex: 1; */
  text-align: right;
}

.order-details-page-card-left-text,
.order-details-page-card-right-text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: medium;
}

.order-total-container {
  margin-top: 1rem;
  border-top: 1px solid #f0f0f0;
  padding-top: 1rem;
}

.order-total-heading {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: larger;
  font-weight: 600;
  color: #757575;
}

.order-total-text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: larger;
  font-weight: 600;
  color: #212121;
}
