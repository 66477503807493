.app {
  /* position: relative;
    height: 93vh;
    background-size: cover;
    justify-content: space-between; */
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-self: center;
  margin-bottom: 3%;
}

input[type="checkbox"] {
  accent-color: #1e799c;
}

.background-thumbnail {
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  object-fit: cover;
  z-index: -1;
}

.chat-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 480px;
  left: 338px;
  background: transparent;
  border: 0;
}

.live-streams-container {
}

.live-stream-container {
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1));
  padding: 5vw 5vw 5vw 5vw;
  border-radius: 8px;
  border: 1px solid #e5e5e8;
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
}
