.user-details-centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-left: 3%;
  margin-right: 3%;
}

.user-details-joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 25px;
}

.user-details-joinChatContainerss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.user-details-joinChatHeading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
  align-self: center;
  font-family: Plus Jakarta Sans;
}
.user-details-joinChatSubHeading {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: grey;
  align-self: center;
  font-family: Plus Jakarta Sans;
}

.user-details-joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: Plus Jakarta Sans;
}
.user-details-joinChatContainer input {
  width: 90%;
  height: 40px;
  margin: 7px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}

.user-details-joinChatContainerss input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}

.user-details-joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #f5f2fc;
  color: #9ea2b3;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.user-details-joinChatContainerss button {
  width: 95%;
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.user-details-joinChatContainer button:hover {
  background: #1e799c;
  color: white;
}

.user-details-joinChatContainerss button:hover {
  background: #1e799c;
  color: white;
}

.user-details-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3%;
}

.user-details-input {
  width: 95% !important;
  margin-left: 0 !important;
  padding-left: 3% !important;
  font-family: Plus Jakarta Sans !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.user-details-input-label {
  font-weight: 500;
  color: #111827;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
}
