/* Overlay */
.category-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
.category-popup-overlay-desktop {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  /* display: flex; */
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
.category-popup-overlay.close {
  opacity: 0;
  pointer-events: none; /* Disable interactions when closing */
}

/* Popup Content */
.filterby-desktop-category-popup-content {
  background: white;
  width: 360px;
  border-radius: 8px;
  border-width: 1px;
  box-shadow: 2px 4px 20px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

/* Header (Title and Close Button) */
.filterby-desktop-category-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.filterby-desktop-category-popup-header:empty {
  display: none;
}

.filter-category-back-button {
  display: flex;
  justify-content: start;
  align-items: center;

  background: var(--White, #fff);
  border-width: 0px;
  cursor: pointer;
}
.category-popup-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}
.filter-category-back-button-svg {
  width: 24px;
  height: 24px;
}
.filter-category-back-button-text {
  color: var(--Black, #161719);
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

/* Close Button */
.category-popup-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* List */
.filter-by-category-popup-list {
  list-style: none;
  padding: 0;
  margin: 4px 0 0;
  max-height: 350px;
  overflow-y: auto;
  background: var(--White, #fff);
  gap: 0px;
  width: 100%;
}
.filter-by-category-popup-list::-webkit-scrollbar {
  display: none;
}

.filter-by-category-popup-item {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 10px 20px;
}
.filter-by-category-popup-item:hover {
  background: var(--Light-Grey, #e3e3e3);
}

.filter-by-item-text {
  font-size: 16px;
  font-style: normal;
  color: var(--Black, #161719);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
}
.filter-by-item-text.selected {
  color: var(--Brand, #207a9c);
}

.category-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-popup-title {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* Slide Animations */
.category-popup-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

.category-popup-list-enter {
  animation-name: slide-in;
}

.category-popup-list-exit {
  animation-name: slide-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* List Wrapper for Animation */
.filter-by-category-popup-list-wrapper {
  position: relative;
  overflow: hidden;
  height: auto; /* Adjust height as needed */
}

.category-popup-list {
  list-style: none;
  padding: 0;
  margin: 0;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.filter-by-category-popup-list-wrapper.slide-out {
  animation: slide-out 0.3s ease;
}

.filter-by-category-popup-list-wrapper.slide-in {
  animation: slide-in 0.3s ease;
}

@keyframes slide-out {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.filter-by-category-checkable-item {
  display: flex;
  align-items: center;
  justify-self: start;
  border: 0;
  cursor: pointer;
  height: 44px;
  margin: 0px;
  width: 320px;
  justify-content: space-between;
  padding: 10px 20px;
}
.filter-by-category-checkable-item:hover {
  background: var(--Light-Grey, #e3e3e3);
}
.filter-by-category-item-text {
  font-size: 1rem;
  color: var(--Black, #161719);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
}

.filter-by-category-check-box {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
