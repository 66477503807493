.search-products-product-section-product-page {
  padding: 12px;
  /* border:2px solid green; */
}

.search-products-product-section-product-page-heading {
  padding: 18px;
  text-align: center;
}

.search-products-product-section-product-page-subcategories {
  padding: 10px;
}

.search-products-product-section-categories {
  padding: 10px;
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.search-products-product-section-category-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.search-products-product-section-product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  padding: 15px;
}

.search-products-product-section-product-card {
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #f9fafb;
}

.search-products-product-section-product-image-box {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.search-products-product-section-product-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.search-products-product-section-info {
  padding: 10px;
}

.search-products-product-section-description {
  color: #6b7280;
  overflow: hidden;
  font-size: 0.75rem;
}

.search-products-product-section-price {
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.search-products-product-section-original-price {
  text-decoration: line-through;
  color: #9ca3af;
  font-weight: 400;
  font-size: 0.625rem;
}

.search-products-product-section-rating {
  color: #ffa500;
}

.search-products-product-section-tag-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-products-product-section-tag-cover {
  margin-top: 5%;
  background-color: #dc2626;
  padding: 1% 2% 2% 5%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: fit-content;
  text-align: right;
}

.search-products-product-section-tag-cover-text {
  color: #fef2f2;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
}

.no-products-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin: 20px;
  top: 20%;
  margin-top: 29%;
}

.no-products-found h2 {
  font-size: 1.5rem;
  color: #333;
}

.no-products-found p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

.storefront-skeleton-products {
  margin-right: 3%;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.storefront-skeleton-product-card {
  width: 100%;
  height: 350px;
  background: linear-gradient(90deg, #f9f9fa 25%, #fcfcfc 50%, #f8f9fa 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-image {
  margin: 5% 5% 7% 5%;
  width: 95%;
  height: 250px;
  background: linear-gradient(90deg, #a3a3bb 25%, #a2b4bd 50%, #94a4b4 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-name {
  margin-top: 5%;
  margin-left: 5%;
  height: 10px;
  width: 40%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-description {
  margin-top: 5%;
  margin-left: 5%;
  height: 10px;
  width: 80%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-price {
  margin-top: 5%;
  margin-left: 5%;
  height: 15px;
  width: 30%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

/* Media query for desktop (large screens) */
@media (min-width: 1024px) {
  .search-products-product-section-product-grid {
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns for larger screens */
  }

  /* Image container */
  .category-product-image-container {
    width: 100%;
    height: 230px;
    position: relative;
    overflow: hidden;
  }
}
