.streamlist-skeleton-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.streamlist-skeleton {
  opacity: 0.7;
  animation: streamlist-skeleton-loading 1s linear infinite alternate;
}

.streamlist-skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.streamlist-skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes streamlist-skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
