.avatar {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
}

/* FOR BACKGROUND COLOR */
.bg-slate {
  background-color: #e2e8f0;
} /* slate-200 */
.bg-teal {
  background-color: #b2f5ea;
} /* teal-200 */
.bg-red {
  background-color: #fc8181;
} /* red-200 */
.bg-green {
  background-color: #c6f6d5;
} /* green-200 */
.bg-yellow {
  background-color: #faf089;
} /* yellow-200 */
.bg-gray {
  background-color: #e2e8f0;
} /* gray-200 */
.bg-cyan {
  background-color: #e0f2fe;
} /* cyan-200 */
.bg-sky {
  background-color: #bae6fd;
} /* sky-200 */
.bg-blue {
  background-color: #bfdbfe;
} /* blue-200 */

/*FOR TEXT COLOR*/
.bg-slate {
  color: #2d3748;
} /* slate-800 */
.bg-teal {
  color: #22543d;
} /* teal-800 */
.bg-red {
  color: #9b2c2c;
} /* red-800 */
.bg-green {
  color: #2f855a;
} /* green-800 */
.bg-yellow {
  color: #9c5b20;
} /* yellow-800 */
.bg-gray {
  color: #4a5568;
} /* gray-700 */
.bg-cyan {
  color: #0f766e;
} /* cyan-800 */
.bg-sky {
  color: #1e3a8a;
} /* sky-800 */
.bg-blue {
  color: #1e40af;
} /* blue-800 */
