.login-phone-user-details-container {
  font-family: var(--font-family-inter);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
}
.login-phone-user-details-header {
  width: 100%;
}

.login-phone-user-details-header-text {
  overflow: hidden;
  color: var(--content-primary, #141414);
  text-overflow: ellipsis;
  font-size: var(--label-large);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-large);
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  margin-top: var(--spacingx3);
  margin-bottom: var(--spacingx3);
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  font-family: var(--font-family-inter);
}

.login-phone-user-details-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx3));
  height: 100%;
  padding-left: var(--spacingx3);
  font-family: var(--font-family-inter);
}

.login-phone-user-details-body-text {
  margin-top: var(--spacingx3);
  overflow: hidden;
  color: var(--content-primary, #141414);
  text-overflow: ellipsis;
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  font-family: var(--font-family-inter);
}

.login-phone-user-details-body-input-container {
  display: flex;
  width: calc(var(--full-screen-width) - 4 * var(--spacingx3));
  padding: var(--spacingx3);
  margin-top: var(--spacingx2);
  align-items: center;
  gap: var(--spacingx2);
  align-self: stretch;
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-primary);
  background: var(--surface-primary, #fff);
  font-family: var(--font-family-inter);
}

.login-phone-user-details-body-input {
  display: flex;
  flex-direction: row;
  color: var(--content-primary);
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  width: 100%;
  height: 23px;
  border: none;
  outline: none;
  background: transparent;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0;
  font-family: var(--font-family-inter);
}

.login-phone-user-details-continue-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  padding-bottom: var(--spacingx3);
  padding-top: var(--spacingx3);
  font-family: var(--font-family-inter);
}
.login-phone-user-details-continue-button {
  display: flex;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding: var(--spacingx4) var(--spacingx8);
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: var(--radius-large);
  background: var(--surface-inverted);
  border: none;
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-inverted);
  font-family: var(--font-family-inter);
}

.login-phone-user-details-continue-button:disabled {
  background: var(--surface-action-2-action-2-disabled);
  color: var(--content-inactive);
  font-family: var(--font-family-inter);
}
