.ChatSuggestionModal__modal {
  background: white; /* Background color of the modal */
  border: 1px solid #ddd; /* Border for the modal */
  padding: 10px; /* Padding inside the modal */
  border-radius: 5px; /* Slight rounding of corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for a pop-out effect */
  max-width: 100%; /* Ensure it does not exceed the input width */
  overflow: auto; /* Scroll if needed */
  width: 75%;
}

.ChatSuggestionModal__modal ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.ChatSuggestionModal__modal li {
  cursor: pointer; /* Change cursor on hover */
  padding: 5px;
}

.ChatSuggestionModal__modal li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.ChatSuggestionModal__closeButton {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.ChatSuggestionModal__closeButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
