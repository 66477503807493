.category-product-card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
  cursor: pointer;
}

.category-product-card .heading {
  display: flex;
  gap: 0.25rem;
}

.category-product-card .heading span {
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  font-family: "Jakarta", sans-serif;
}

.category-product-card .heading span:first-child {
  color: #9ca3af; /* gray-400 */
}

.category-product-card .heading span:last-child {
  color: #1e799c; /* #1E799C */
}

.category-product-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 1rem;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  gap: 0.75rem;
  margin-top: 1rem;
}
.images-grid-v2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  gap: 0.75rem;
  margin-top: 1rem;
}

.images-grid-v2 :nth-child(3) {
  grid-column: span 2;
}
.category-product-card .images-grid-v2 img {
  width: 100%;
  height: 12rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.category-product-card img {
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.category-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.featured-card {
  grid-row: span 2;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px) {
  .category-product-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .category-product-grid {
    grid-template-columns: 1fr;
  }

  .featured-card {
    grid-column: span 1;
    grid-row: span 1;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
