.background-thumbnail {
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
  border-radius: 8px;
}

.chat-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 480px;
  left: 338px;
  background: transparent;
  border: 0;
}

.logout-button {
  border: none;
  border-radius: 8px;
  border-radius: 5px;
  /* border: 1px solid #1e799c; */
  padding: 5px;
  font-size: 16px;
  background: #30565c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.remind-me-live-stream {
  color: #374151;
  border: 2px solid #e5e7eb;
  /* width: 40%; */
  padding: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  display: flex;
  background-color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  height: 25px;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 150px;
  cursor: pointer;
}
