.mobile-home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding-left: 2%;
  top: 0%;
  z-index: 99;
  padding-right: 5%;
  background: #30565c;
  position: sticky;
  width: -webkit-fill-available;
  /* margin-bottom: 2%; */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  height: 125px;
}

.categories-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  display: flex;
  /* border: 1px solid #1e799c; */
  padding: 5px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.header-logout-content-container {
  padding: 0% 5% 5% 5%;
}

.header-logout-button {
  border: none;
  display: flex;
  /* border-radius: 8px;
  border-radius: 5px; */
  /* border: 1px solid #1e799c; */
  padding: 5px;
  font-size: 16px;
  background: #30565c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  /* height: 80px;
  padding-top: 22px; */
}

.logout-yes-button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: white;
  color: #dc3545;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #dc3545;
}

.logout-no-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.header-cart-text {
  margin-top: 4px;
  color: white;
  font-size: "10px";
  font-family: "Plus Jakarta Sans";
}

.header-left-container {
  display: flex;
  text-align: right;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  margin-left: 8px;
}

.header-left-container-cart {
  margin-top: 4px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.desktop-home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding-left: 2%;
  top: 0%;
  z-index: 99;
  padding-right: 5%;
  background: #30565c;
  position: sticky;
  width: -webkit-fill-available;
  margin-bottom: 2%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  height: 80px;
}
