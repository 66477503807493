.add-address-container {
  padding: 1% 3% 1% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-address-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3%;
  width: 100%;
}

.add-address-input {
  margin-left: 0 !important;
  padding-left: 0.75rem !important;
  border-radius: 0.5rem !important;
  height: 40px !important;
  border: 1px solid #e5e5e8 !important;
}

.add-address-input-label {
  font-weight: 500;
  color: #222026;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  margin-bottom: 5px;
  font-style: normal;
}

.add-address-action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3%;
  width: 48%;
  column-gap: 4%;
}

.add-address-input-container {
  margin-top: 4%;
  margin-bottom: 40%;
  width: 95%;
}

.add-address-negative-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: white;
  color: #1e799c;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #1e799c;
}

.add-address-positive-button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  padding: 9px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  display: flex;
  justify-content: center;
}

.add-address-positive-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
