.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  flex-direction: column;
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 2%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev {
  position: absolute;
  left: 3%;
}

.next {
  position: absolute;
  right: 3%;
}

.carousel-content {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  height: 100%;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item:not(.active) {
  opacity: 0;
}

.carousel-indicator {
  padding: var(--spacingx2) 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.dots-container {
  display: flex;
  gap: var(--spacingx1);
  position: relative;
  align-items: center;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #d1d5db;
}

.dot.active {
  width: 8px;
  height: 8px;
  background-color: var(--content-active);
}

.active-dot {
  position: absolute;
  left: 0;
  top: 0;
  width: 1rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #1e799c;
  transition: transform 0.3s ease;
}
