.avatar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.avatar_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
