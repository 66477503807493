/* General Skeleton Styling */
.storefront-skeleton-header,
.storefront-skeleton-stats,
.sstorefront-keleton-products {
  margin-top: 20px;
  margin-left: 15px;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.storefront-keleton-header {
  border-bottom: 1px solid #ddd;
}

.storefront-skeleton-store-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.storefront-skeleton-stats {
  margin-top: 1px;
  margin-bottom: 30px;
  margin-left: 17%;
  width: 60%;
  justify-content: space-between;
  /* border-bottom: 1px solid #ddd; */
}

.storefront-skeleton-tabs {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  height: 35px;
}

.storefront-skeleton-products {
  margin-right: 3%;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.storefront-skeleton-product-card {
  width: 100%;
  height: 350px;
  background: linear-gradient(90deg, #f9f9fa 25%, #fcfcfc 50%, #f8f9fa 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-image {
  margin: 5% 5% 7% 5%;
  width: 95%;
  height: 250px;
  background: linear-gradient(90deg, #a3a3bb 25%, #a2b4bd 50%, #94a4b4 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-name {
  margin-top: 5%;
  margin-left: 5%;
  height: 10px;
  width: 40%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-description {
  margin-top: 5%;
  margin-left: 5%;
  height: 10px;
  width: 80%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-product-card-price {
  margin-top: 5%;
  margin-left: 5%;
  height: 15px;
  width: 30%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

/* Skeleton Elements */
.storefront-skeleton-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-text {
  height: 10px;
  width: 80%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-text.small {
  width: 40%;
}

.storefront-skeleton-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.storefront-skeleton-circle {
  margin-bottom: 20%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-free-delivery {
  margin-left: 8%;
  margin-top: 10px;
  width: 85%;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1s infinite;
}

.storefront-skeleton-tab {
  margin-top: 7px;
  width: 30%;
  height: 15px;
  border-radius: 5px;
  background: linear-gradient(90deg, #b3c1cc 25%, #b3c1cc 50%, #b3c1cc 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1s infinite;
}

/* Skeleton Loading Animation */
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
    background-color: hsl(200, 20%, 50%);
  }

  100% {
    background-position: -200% 0;
    background-color: hsl(200, 20%, 95%);
  }
}
