.orderDetailsContainer {
  padding: 9px;
  border-radius: 8px;
  margin-top: 10%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0d0d0;
  background-color: #fff;
  font-family: Plus Jakarta Sans;
}

.orderDetailsHeadingContainer {
  padding-left: 2%;
}

.orderDetailsHeadingText {
  font-family: Plus Jakarta Sans;
  font-size: medium;
  font-weight: 600;
}

.orderDetailsBodyContainer {
  padding: 0 2%;
}

.orderDetailsRowContainer {
  display: flex;
  padding: 1% 0;
}

.orderDetailsRowLeftContainer {
  width: 70%;
  display: flex;
  align-items: flex-start;
}

.orderDetailsRowRightContainer {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.orderDetailsLeftText {
  color: #8a8a8a;
  font-family: Plus Jakarta Sans;
  font-size: small;
}

.orderDetailsRightText {
  color: #8a8a8a;
  font-family: Plus Jakarta Sans;
  font-size: small;
}

.discountText {
  color: #ff7a00;
  /* Accent color for discount */
}

.freeText {
  color: #2dab1c;
  /* Light gray for free delivery charge */
}

.orderTotalRow {
  margin-top: 1%;
  border-top: 1px solid #938e8e;
}

.orderTotalHeading {
  color: #7b7589;
  font-family: Plus Jakarta Sans;
  font-size: medium;
  font-weight: 600;
}

.orderTotalText {
  color: #333333;
  font-family: Plus Jakarta Sans;
  font-size: medium;
  font-weight: 600;
}
