/* Container to hold the custom radio button */
.custom-radio {
  display: flex; /* Use flex for alignment */
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin: 10px 0; /* Vertical margin for spacing between radio buttons */
  color: #333;
  min-width: 200px; /* Ensure a minimum width for the label */
}

/* Hide the original radio button */
.custom-radio input[type="radio"] {
  display: none;
}

/* Custom radio button appearance */
.radio-mark {
  width: 20px; /* Set a fixed width */
  height: 20px; /* Set a fixed height */
  min-width: 20px;
  max-width: 20px;
  border: 1px solid #938e9e;
  border-radius: 50%;
  margin-right: 10px; /* Adjusted margin for consistent spacing */
  position: relative;
  transition: background 0.3s;
}

/* The dot inside the radio button when checked */
.radio-mark::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #1e799c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
}

/* Show the dot when the radio button is checked */
.custom-radio input[type="radio"]:checked + .radio-mark::after {
  transform: translate(-50%, -50%) scale(1);
}

/* Optional hover effect for better interaction */
.custom-radio:hover .radio-mark {
  background-color: rgba(0, 123, 255, 0.1);
}

/* Optional focus effect when tabbing through */
.custom-radio input[type="radio"]:focus + .radio-mark {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
}

.custom-radio-address-v2 {
  display: flex; /* Use flex for alignment */
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin: 10px 0; /* Vertical margin for spacing between radio buttons */
  color: #333;
  min-width: 200px; /* Ensure a minimum width for the label */
  background-color: white;
  padding-left: 7px;
}
