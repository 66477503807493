.select-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.select-address-input-container {
  margin-top: 4%;
  margin-bottom: 20%;
  width: 95%;
}

.select-address-action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3%;
  width: 48%;
  column-gap: 4%;
}

.select-address-negative-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.75rem;
  background: white;
  color: #1e799c;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #1e799c;
}

.select-address-positive-button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  padding: 9px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  display: flex;
  justify-content: center;
}

.select-address-positive-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.select-address-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.address-label-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: "10%";
}

.address-label-header-text {
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: right;
}

.address-label-body-text {
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
}

.select-address-action-wrapper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  z-index: 1000;
  /* box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1); */
  /* background: white, */
}
