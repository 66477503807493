.stream-container {
  height: 100dvh;
  /* width: 100dvw; */
  display: flex;
  justify-content: center;
}

.view-count {
  padding: 2px 4px 2px 4px;
  gap: 6px;
  border-radius: 6px;
  background: #00000040;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.live-container {
  width: 825px;
  position: absolute;
  top: 8.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.stream-body-container {
  height: 100dvh;
  width: 100dvw;
  max-width: 1000px;
  position: fixed;
}

.stream {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fullscreen-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensures the video stays in the background */
}

.stream-live {
  border-radius: 6px;
  background: #d9342b;
  text-align: center;
  padding: 3px 6px 3px 6px;
  gap: 6px;
}

.exit-stream {
  width: 90px;
  height: 30px;
  padding: 8px 12px 8px 12px;
  gap: 6px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #d9342b;
  box-shadow: 0px 1px 2px 0px #1018280a;
  font-family: Inter;
  font-size: 11px;
  color: #d9342b;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-decoration-color: #1e799c;
  cursor: pointer;
  background: transparent;
  z-index: 10;
}

.exit-stream-content-container {
  padding: 0% 5% 5% 5%;
}

.exit-stream-yes-button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: white;
  color: #dc3545;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #dc3545;
}

.exit-stream-no-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.chat-wrapper-container {
  bottom: 0;
  position: absolute;
  width: 100%;
  margin-bottom: 5%;
}

.stream-video-action-container {
  top: 40%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 1000;
}

.stream-video-action-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.stream-video-action-item-logo {
  padding: 0.3rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stream-video-action-item-title {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
}

.stream-video-url-generated {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background: black;
  display: flex;
  flex-direction: column;
  column-gap: 0;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.live {
  border-radius: 6px;
  background: #d9342b;
  text-align: center;
  padding: 6px 12px 6px 12px;
  gap: 6px;
}

.live-text {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .live-container {
    width: 90%;
    top: 5%;
  }

  .chat-wrapper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stream-video-action-container {
    right: 3%;
  }
}
