.footer {
  margin-top: 5%;
  background-color: #ffffff;
  padding: 20px 40px;
  font-family: Arial, sans-serif;
  color: #6b7280;
  /* Neutral gray text color */
}

.footer__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.footer__download-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.footer__download-buttons span {
  cursor: pointer;
}

.footer__logo-links {
  flex: 1;
  margin-bottom: 20px;
}

.footer__logo-img {
  height: 100px;
}

.footer__links {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 14px;
  color: #374151;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.footer__download {
  color: #374151;
  font-weight: 500;
  font-family: Plus Jakarta Sans;
  margin-top: 50px;
}

.footer__download span {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.footer__download-buttons img {
  height: 40px;
  margin-right: 10px;
}

.footer__bottom {
  border-top: 1px solid #e5e7eb;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer__social img {
  height: 20px;
  margin-right: 10px;
}

.footer__legal {
  font-size: 12px;
  text-align: center;
  display: flex;
  gap: 3rem;
  font-family: Plus Jakarta Sans;
}

.footer__legal a {
  color: #6b7280;
  text-decoration: none;
}

.footer__legal a:hover {
  text-decoration: underline;
}

.footer__logo {
  margin-left: -7px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer__top {
    flex-direction: column;
    text-align: center;
  }

  .footer__download {
    text-align: left;
    margin-top: 20px;
    margin-left: -15px;
  }

  .footer__bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer__social {
    margin-bottom: 10px;
  }

  .footer__links {
    flex-direction: column;
  }
  .footer__logo-img {
    margin: 0;
  }

  .footer__logo {
    margin-left: -37px;
  }
}
