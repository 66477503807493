.order-details-page-container {
  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.order-details-page-product-card-ctn {
  border: 1px solid #e0e0e0;
}

.order-details-heading {
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 5%;
  margin-left: 2%;
}

.orderdetailspage-alert-contact-container {
  align-items: center;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
}

/* Style for the alert container */
.orderdetailspage-alert-header-container {
  display: flex;
  justify-content: center;
  text-decoration: none; /* Remove default link underline */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure the corners are clean */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  width: full;
  align-items: center;
  margin-bottom: 10%;
}

/* Style for the alert header text */
.orderdetailspage-alert-header {
  color: #1e799c; /* White text */
  background-color: #eefbfd;
  padding: 5px 6px; /* Padding around the text */
  font-size: 14px; /* Font size */
  font-weight: bold; /* Bold font */
  text-align: center; /* Center align text */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.order-status-selfship-card {
  width: 96%;
  border: 1px solid #e0e0e0;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.download-invoice-button {
  font-size: medium;
  width: 96%;
  border-radius: 8px;
  margin-left: 2%;
  margin-right: 2%;
  padding: 1%;
  font-weight: 600;
  background-color: white;
  border-width: 1px;
  border-color: #1e799c;
  margin-bottom: 30%;
  height: 200%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #1e799c;
}
