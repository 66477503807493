.option-slider {
  width: 100%;
}

.slider-container {
  display: flex;
  align-items: center;
}

.options-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
  gap: var(--spacingx2);
  /* Adjust based on option width and margin */
}
.slider-button {
  background: none;
  border: none;
  font-size: 24px;
  padding: 0 10px;
}
.slider-button:disabled {
  color: #ccc;
}
