.coupon-card-container {
  padding: 9px;
  border-radius: 8px;
  margin-top: 10%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0d0d0;
  background-color: #fff;
  font-family: Plus Jakarta Sans;
}

.coupon-card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.coupon-card-header-left-container {
  display: flex;
  /* width: 80%; */
}

.coupon-card-header-right-container {
  display: flex;
  justify-content: center;
  /* width: 20%; */
}

.home-svg-container {
  align-self: center;
  margin-right: 3%;
}

.arrow-right-svg-container {
  align-self: flex-end;
}

.home-svg,
.arrow-right-svg {
  width: 2rem;
  /* Adjust based on your design */
  height: 2rem;
  color: #333;
  /* Set the color of the SVG */
}

.coupon-card-header-text {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  color: #222026;
  line-height: 20px;
  font-weight: 400;
  margin-left: 3px;
  white-space: nowrap;
}

.coupon-card-header-applied-text {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  color: #068a0c;
  line-height: 20px;
  font-weight: 600;
  margin-left: 3px;
  white-space: nowrap;
}

.coupon-card-body-container {
  padding: 2% 2%;
}

.coupon-card-change-button {
  font-family: Plus Jakarta Sans;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #1e799c;
  margin-right: 4px;
  position: relative;
  padding: 0;
  margin: 0;
}
