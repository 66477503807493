.chat-box {
  max-height: 30%;
  overflow-y: auto;
  padding: 3%;
  align-self: flex-start;
}

.chat-message {
  align-items: center;
  margin-bottom: 2%;
  max-height: 35vh;
  overflow-y: auto;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: "flex-start";
  margin-bottom: 1.5vh;
  max-width: 88%;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: white; /* Darker text for username */
}

.chat-timestamp {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #888; /* Lighter color for timestamp */
  margin-left: 0.5rem;
}

.message {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: white; /* Darker text for better readability */
  margin-top: 0.15rem; /* Space between the message and header */
}

.mention-suggestion {
  position: absolute; /* Allow positioning relative to the parent */
  display: flex;
  justify-content: flex-start;

  bottom: 32%; /* Place it above the input with a gap */
  left: 5%; /* Align it with the left side of the input */
  /* right: 14%; Optional: stretch across full width */
  width: 100%;
  z-index: 1000; /* Ensure it appears above other elements */
}

.chat-input {
  display: flex;
  align-items: center;
  width: 95%;
  height: 40px;
  border-radius: 20px;
  padding: 0 8px;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Closer to white with slight transparency */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.chat-input input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 20px;
  background: transparent;
  color: black; /* Changed to black for better contrast */
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.send-button {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background: #1e799c;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
}

.send-button:hover {
  background: #155d7a; /* Slightly darker blue on hover */
}

.send-button svg {
  color: white;
}

.chat-message-history {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  margin: 0.15rem;
  max-width: 70%;
}

.chat-message-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
}

.chat-highlight-mention {
  color: white; /* Highlight color for mentions */
  font-weight: 600;
  background-color: rgb(255, 255, 255, 0.2);
  padding: 0.15rem;
  margin-right: 0.2rem;
}

@media only screen and (max-width: 1000px) {
  .chat-box {
    width: 95%;
  }
}
