.login-phone-container {
  background: var(--surface-primary);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  font-family: var(--font-family-inter);
}
.login-phone-header {
  width: 100%;
}
.login-phone-header-text {
  font-size: var(--label-large);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-large);
  color: var(--content-primary);
  padding: var(--spacingx3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-family: var(--font-family-inter);
}

.login-phone-input-number {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
  padding-bottom: var(--spacingx3);
  padding-top: var(--spacingx3);
  width: calc(var(--full-screen-width) - 2 * var(--spacingx3));
  font-family: var(--font-family-inter);
}

.login-phone-input-number-text {
  color: var(--content-secondary);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  font-family: var(--font-family-inter);
}

.login-phone-input-number-container {
  margin-top: var(--spacingx2);
  display: flex;
  padding: var(--spacingx3);
  align-items: center;
  gap: var(--spacingx2);
  align-self: stretch;
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-primary);
  background: var(--surface-primary);
  font-family: var(--font-family-inter);
}
.login-phone-input-number-container:focus {
  background: var(--surface-secondary-pressed);
}
.login-phone-input-number-container-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--content-primary);
  text-align: center;
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  padding: 0;
  font-family: var(--font-family-inter);
}
.login-phone-input-number-input {
  display: flex;
  flex-direction: row;
  color: var(--content-primary);
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0;
  font-family: var(--font-family-inter);
}

.login-phone-policy-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx3));
  height: 100%;
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
  font-family: var(--font-family-inter);
}

.login-phone-policy-container-checkbox {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 16px;
  height: 16px;
  margin: 0px;
  margin-right: var(--spacingx1);
  font-family: var(--font-family-inter);
}

.login-phone-policy-container-text {
  color: var(--content-secondary);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  font-family: var(--font-family-inter);
}

.login-phone-policy-container-text-link {
  color: var(--content-action);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  text-decoration: none;
  font-family: var(--font-family-inter);
}

.login-phone-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  padding-bottom: var(--spacingx3);
  padding-top: var(--spacingx3);
  font-family: var(--font-family-inter);
}

.login-phone-button {
  display: flex;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding: var(--spacingx4) var(--spacingx8);
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: var(--radius-large);
  background: var(--surface-inverted);
  border: none;
  font-family: Inter;
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-inverted);
  font-family: var(--font-family-inter);
}

.login-phone-button:disabled {
  background: var(--surface-action-2-action-2-disabled);
  color: var(--content-inactive);
  font-family: var(--font-family-inter);
}
