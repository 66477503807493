/* .live-card-bg {
    position: absolute;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQzMiIgaGVpZ2h0PSIxNDMzIiB2aWV3Qm94PSIwIDAgMTE0NSAxNDMzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzE1OTVfNTc4OSkiPgo8Y2lyY2xlIGN4PSI3MTYuNSIgY3k9IjcxNi41IiByPSIzMTYuNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzE1OTVfNTc4OSkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzE1OTVfNTc4OSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE0MzMiIGhlaWdodD0iMTQzMyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyMDAiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xNTk1XzU3ODkiLz4KPC9maWx0ZXI+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNTk1XzU3ODkiIHgxPSI0MDAiIHkxPSI0MDAiIHgyPSIxMDMzIiB5Mj0iMTAzMyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY1RjZEIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGQzM3MSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    width: 100%;
    height: 70%;
    z-index: -1;
    top: 59%;
    right: 0%;
    background-size: cover;
    background-position: center;
} */
.live-card-comet {
  position: absolute;
  top: 74%;
  right: 4%;
}

.live-cards {
  width: 215px;
}
.live-card-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  font-family: Plus Jakarta Sans;
}

.live-card-header {
  display: flex;
  /* gap: 0.85rem; */
  align-items: center;
  margin-left: 2rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.live-card-header span {
  font-size: 2.25rem;
  font-weight: bold;
  font-style: italic;
  font-family: "Jakarta", sans-serif;
}

.live-card-header span:first-child {
  color: #9ca3af;
  /* gray-400 */
}

.live-card-header span:last-child {
  color: #ef4444;
  /* #1E799C */
}

.stream-live-card {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
}

/* Image container */
.stream-live-card-image {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.stream-live-card-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  /* height: 19rem; */
  aspect-ratio: 9/16;
}

.stream-card-live-badge {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #e63946;
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 6px;
  padding: 4px 8px;
}

.stream-card-live-badge-black {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #000000;
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 6px;
  padding: 4px 8px;
}

.stream-live-card-content {
  font-family: Plus Jakarta Sans;
}

.stream-live-name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.stream-live-seller-name {
  font-size: 0.7rem;
  color: #6b7280;
  margin: 0;
  margin-bottom: 0.5rem;
}

.live-card-content ::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  /* For tablet and smaller screens */
  .stream-live-card {
    max-width: 90%;
    /* Reduce card width */
    margin: 0 auto;
    /* Center the card */
  }

  .stream-live-card-content {
    padding: 0.8rem;
  }

  .stream-live-name {
    font-size: 1rem;
    white-space: nowrap;
    overflow-x: scroll;
    /* text-overflow: ellipsis; */
    width: 100%;
    line-height: 1.5;
    max-width: 100%;
    display: block;
    max-height: 3em;
    scrollbar-width: none;
  }

  .stream-live-seller-name {
    font-size: 0.85rem;
  }

  .recommended-price-section {
    font-size: 0.9rem;
  }
  .comet-svg {
    display: flex;
    position: absolute;
    right: 6%;
    top: 52%;
  }

  .live-card-preview-video > div > video {
    border-radius: 12px;
  }

  .live-card-preview-video > div {
    /* height: 14rem; */
    border-radius: 12px;
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .stream-live-card {
    width: 155px;
    max-width: 100%;
    box-shadow: none;
  }

  .stream-live-card-content {
    padding: 0.5rem;
  }

  .stream-live-name {
    font-size: 0.95rem;
    white-space: nowrap;
    overflow-x: scroll;
    /* text-overflow: ellipsis; */
    width: 100%;
    line-height: 1.5;
    max-width: 100%;
    display: block;
    max-height: 3em;
    scrollbar-width: none;
  }

  .stream-live-seller-name {
    font-size: 0.8rem;
  }

  .recommended-price-section {
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 0.3rem;
  }

  .stream-card-live-badge {
    font-size: 0.7rem;
    padding: 3px 6px;
  }
}

@media only screen and (max-width: 568px) {
  .stream-live-card-image img {
    width: 100%;
    /* height: 14rem; */
  }

  .recommended-product {
    width: 136px;
    height: 309px;
  }

  .live-card-header {
    display: flex;
    /* gap: 0.65rem; */
    align-items: center;
    margin-left: 1rem;
    margin-top: 3rem;
  }

  .live-card-header span {
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    font-family: "Jakarta", sans-serif;
  }

  .live-card-comet {
    width: 100px;
    height: 100px;
    margin-top: 36%;
  }

  .live-cards {
    width: 150px;
  }

  .live-card-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: 20px;
    /* border: 1px solid red; */
    font-family: Plus Jakarta Sans;
    /* height: 310px; */
    overflow-y: auto;
    padding-left: 22px;
  }

  .live-card-preview-video > div > video {
    /* height: 14rem; */
    border-radius: 12px;
  }

  .live-card-preview-video > div {
    /* height: 14rem; */
    border-radius: 12px;
  }
}

.live-card-preview-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  margin: 100;
  padding: 100;
}

.video-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}

.video-popup .video-clip {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 10px;
}

.live-card-preview-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
}

.live-card-preview-video > div > video {
  /* height: 14rem; */
  border-radius: 12px;
}

.live-card-preview-video > div {
  /* height: 14rem; */
  border-radius: 12px;
}

.live-card-preview-no-preview {
  justify-content: center;
  flex: 1;
  align-self: center;
  padding-top: 50%;
  color: #999;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
}

.no-preview-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #999;
  text-align: center;
  margin-bottom: 100%;
}

.preview-image {
  /* height: 10rem; */
}

@media (max-width: 600px) {
  .live-card-preview-video > div > video {
    /* height: 14rem; */
    border-radius: 12px;
  }

  .live-card-preview-video > div {
    /* height: 14rem; */
    border-radius: 12px;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .live-card-preview-video > div > video {
    /* height: 19rem; */
    border-radius: 12px;
  }

  .live-card-preview-video > div {
    /* height: 19rem; */
    border-radius: 12px;
  }
}

@media (min-width: 769px) {
  .live-card-preview-video {
    border-radius: 12px;
  }

  .live-card-preview-video > div {
    /* height: 18.3rem; */
    border-radius: 12px;
    background-color: transparent;
    position: relative;
  }

  .live-card-preview-video > div > .hls-video {
    border-radius: 12px;
    /* height: 15rem; */
  }
}
