.cls-1 {
  fill: #e48025;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  stroke-width: 0px;
}

.cls-5 {
  fill: none;
  stroke: #010101;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.59px;
}

.cls-2 {
  fill: #256a88;
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  fill: #f19724;
}
