.gradient-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.gradient-background {
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(to bottom, #60abb8 5%, #ebf5f6 50%, #ebf5f6 100%);
}

/* .live-card-bg {
    position: absolute;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQzMiIgaGVpZ2h0PSIxNDMzIiB2aWV3Qm94PSIwIDAgMTE0NSAxNDMzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzE1OTVfNTc4OSkiPgo8Y2lyY2xlIGN4PSI3MTYuNSIgY3k9IjcxNi41IiByPSIzMTYuNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzE1OTVfNTc4OSkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzE1OTVfNTc4OSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE0MzMiIGhlaWdodD0iMTQzMyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyMDAiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xNTk1XzU3ODkiLz4KPC9maWx0ZXI+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNTk1XzU3ODkiIHgxPSI0MDAiIHkxPSI0MDAiIHgyPSIxMDMzIiB5Mj0iMTAzMyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY1RjZEIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGQzM3MSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 155%;
    right: 0%;
    background-size: cover;
    background-position: center;

    background: radial-gradient(circle, rgba(255, 94, 58, 0.9) 0%, rgba(255, 153, 102, 0.6) 30%, rgba(255, 204, 153, 0.3) 60%, rgba(255, 255, 255, 0.1) 100%);
}
  */

.content-container {
  position: relative;
  z-index: 10;
  max-width: 1280px;
  margin: 0 auto;
  padding: 48px 16px;
}

.brand-name {
  color: #334155; /* slate-700 equivalent */
}

.home-product-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  gap: 24px;
  padding: 0 16px;
}

.product-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-header {
  color: #0d9488; /* teal-600 equivalent */
  font-weight: 500;
  margin-bottom: 16px;
}

.product-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.product-image {
  aspect-ratio: 3/4;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f3f4f6;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .home-product-grid {
    grid-template-columns: 1fr;
  }

  .main-title {
    font-size: 36px;
  }
}

/* Animation for gradient (optional) */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add this class to gradient-background if you want animation */
.gradient-animated {
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
}

.gradient-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.gradient-stripe {
  position: absolute;
  width: 100%;
  height: 110px;
  transform: skewY(3deg);
  background-color: rgba(255, 255, 255, 0.1);
}

.content-container {
  position: relative;
  z-index: 10;
  top: 12%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 48px 16px;
}

.main-title {
  text-align: center;
  font-size: 62px;
  color: white;
  font-weight: 500;
  margin-bottom: 30px;
  font-family: Bricolage Grotesque;
}

.brand-name {
  color: #334155; /* slate-700 equivalent */
}

.home-product-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-header {
  color: #0d9488; /* teal-600 equivalent */
  font-weight: 500;
  margin-bottom: 16px;
}

.home-product-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.home-product-image {
  aspect-ratio: 3/4;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f3f4f6;
}

.home-product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }

  .main-title {
    font-size: 36px;
    font-family: Bricolage Grotesque;
  }

  .live-card-bg {
    position: absolute;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQzMiIgaGVpZ2h0PSIxNDMzIiB2aWV3Qm94PSIwIDAgMTE0NSAxNDMzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzE1OTVfNTc4OSkiPgo8Y2lyY2xlIGN4PSI3MTYuNSIgY3k9IjcxNi41IiByPSIzMTYuNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzE1OTVfNTc4OSkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzE1OTVfNTc4OSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE0MzMiIGhlaWdodD0iMTQzMyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyMDAiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xNTk1XzU3ODkiLz4KPC9maWx0ZXI+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNTk1XzU3ODkiIHgxPSI0MDAiIHkxPSI0MDAiIHgyPSIxMDMzIiB5Mj0iMTAzMyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY1RjZEIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGQzM3MSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 172%;
    right: 0%;
    background-size: cover;
    background-position: center;
  }
}

/* Animation for gradient (optional) */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add this class to gradient-background if you want animation */
.gradient-animated {
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
}
