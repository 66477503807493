.store-collections-page-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * var(--spacingx3));
  padding: var(--spacingx3);
  background-color: var(--surface-secondary);
  min-height: 100vh;
}

.store-collections-page-collection-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacingx3);
  padding-bottom: var(--spacingx5);
}

.store-collections-page-collection-card {
  display: flex;
  width: calc(100% - 2 * var(--spacingx2));
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacingx2);
  background-color: var(--white);
  border-radius: var(--radius-medium);
  gap: var(--spacingx2);
}

.store-collections-page-collection-card-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.store-collections-page-collection-card-header-name {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-medium);

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.store-collections-page-collection-card-header-item-count {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  color: var(--content-secondary);
  line-height: var(--line-height-small);
}

.store-collections-page-collection-card-image {
  width: 100%;
  height: 160px;
  /* border: 1px solid var(--black); */
  border-radius: var(--radius-medium);
}

.store-collections-page-collection-card-image-img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--radius-medium);
}
