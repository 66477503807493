.order-details-self-ship-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-details-self-ship-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-details-self-ship-row-left {
  width: 40%;
  font-size: medium;
  color: #6b7280;
  flex: 1;
}

.order-details-self-ship-row-right {
  overflow: hidden;
  font-size: medium;
  flex: 1;
  text-align: end;
  white-space: nowrap;
}

.order-details-self-ship-row-right-ctn {
  overflow: hidden;
  font-size: medium;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  padding-right: 10px;
  background-color: transparent;
  border: 0px;
  height: 100%;
}

.order-details-self-ship-row-right-cc {
  height: 100%;
  align-items: center;
}

.order-details-self-ship-row-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 15px;
}

.order-details-self-ship-row-track-button {
  padding: 10px;
  background-color: #1e799c;
}

.order-details-self-ship-row-download-button {
  padding: 10px;
  background-color: #1e799c;
  color: white;
}

.order-details-self-ship-row-right-tracking-url {
  overflow: hidden;
  font-size: medium;
  flex: 1;
  text-align: end;
  white-space: nowrap;
  color: #1e799c;
}
