/* Layout for the grid of product cards */
.category-product-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 columns by default for mobile */
  gap: 2%;
  margin-bottom: 10%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 2%;
}

@media (max-width: 640px) {
  .category-product-list {
    margin-top: 5%;
  }
}

/* Each product card styling */
.category-products-product-card {
  border: 1px solid #eee;
  /* border-radius: 10px; */
  overflow: hidden;
  position: relative;
  background-color: #fff;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease-in-out;
}

/* Hover effect for the card */
.category-product-card:hover {
  transform: translateY(-5px);
}

/* Button that wraps the entire product card */
.category-product-card-button {
  display: block;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
}

/* Image container */
.category-product-image-container {
  width: 100%;
  height: 170px;
  position: relative;
  overflow: hidden;
}

.category-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Discount / Sold Out badge */
.discount-container {
  background-color: #ff4d4d;
  padding: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  z-index: 1;
  font-weight: bold;
}

.discount-container.sold-out {
  background-color: #aaa;
}

.discount-text {
  color: #d81c1c;
  font-size: 14px;
  font-weight: 600;
}

.category-products-product-card .sold-out {
  background-color: #dcdcdc;
  color: #888;
}

/* Product details container */
.product-details-container {
  padding: 15px;
}

/* Product name and description */
.category-product-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.category-product-description {
  font-size: 13px;
  color: #6b7280;
  margin-bottom: 10px;
  line-height: 1.5;
  /* Adjust line height to match your design */
  min-height: calc(1.5em * 2);
  /* Forces a minimum height for 2 lines */

  display: -webkit-box;
  /* Still required for WebKit browsers */
  -webkit-box-orient: vertical;
  /* Required for WebKit browsers */
  line-clamp: 2;
  /* Will clamp the description to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* Fallback for Safari */
}

/* Price section */
.category-product-price-container {
  display: flex;
  align-items: center;
  padding-bottom: 10%;
}

.price {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.old-price {
  font-size: 16px;
  color: #6b7280;
  text-decoration: line-through;
  margin-left: 10px;
}

.category-product-details-container {
  padding: 1rem;
}

.category-products-no-pproducts {
  font-family: Plus Jakarta Sans;
  font-size: larger;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 200px;
}

/* Skeleton */
.skeleton {
  background-color: #f0f0f0;
  /* Light gray background */
  box-shadow: none;
  /* No shadow for skeleton */
  border: 1px solid #ddd;
  overflow: hidden;
  position: relative;
  background: #f0f0f0;
}

/* Skeleton for product image */
.skeleton-image {
  width: 100%;
  height: 170px;
  /* Same height as product image */
  background-color: #ddd;
  /* Light gray background for image */
  animation: shimmer 1.5s infinite linear;
}

/* Skeleton for text (name, description, price) */
.skeleton-text {
  width: 100%;
  height: 16px;
  /* Default height for text */
  background-color: #ddd;
  margin-bottom: 10px;
  animation: shimmer 1.5s infinite linear;
}

/* Skeleton for specific elements like prices */
.skeleton-text.price {
  width: 50%;
}

.skeleton-text.old-price {
  width: 30%;
}

/* Animation for shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
}

.skeleton-text,
.skeleton-image {
  position: relative;
  overflow: hidden;
  display: block;
}

.skeleton-text:before,
.skeleton-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 75%
  );
  animation: shimmer 1.5s infinite linear;
}

.category-products-product-card-skeleton {
  padding: 0;
  flex-direction: column;
  height: 100%;
}

.category-product-image-container-skeleton {
  width: 100%;
  height: 170px;
  border-radius: 0px;
  padding: 0%;
}

.category-product-details-container-skeleton {
  padding: 16px;
}

.category-product-price-container-skeleton {
  display: flex;
  align-items: center;
  padding-bottom: 5%;
}

/* Media query for desktop (large screens) */
@media (min-width: 1024px) {
  .category-product-list {
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns for larger screens */
  }

  /* Image container */
  .category-product-image-container {
    width: 100%;
    height: 230px;
    position: relative;
    overflow: hidden;
  }
}
