/* Main container for the hamburger menu */
.hamburger-menu {
  position: relative;
  display: inline-block;
  z-index: 1000; /* Ensure menu appears above other content */
}

/* Hamburger button styling */
.hamburger-icon {
  cursor: pointer;
  width: 35px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease;
  z-index: 2000;
}

.hamburger-icon:focus {
  outline: 2px solid #1e79cc;
}

/* Hamburger bars */
.bar {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 3px;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.bar.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.bar.open:nth-child(2) {
  opacity: 0;
}

.bar.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Fullscreen overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  right: 0; /* Change to the right side */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with opacity */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.menu-overlay.show {
  opacity: 1;
  pointer-events: auto;
}

/* Sidebar menu styling */
.menu-sidebar {
  position: fixed;
  top: 0;
  right: 0; /* Position menu to the right */
  height: 100%;
  width: 250px;
  background-color: #ffffff;
  padding-top: 20px;
  transform: translateX(100%); /* Start hidden from the right */
  transition: transform 0.3s ease;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
}

.menu-overlay.show .menu-sidebar {
  transform: translateX(0); /* Slide in from the right */
  text-align: left;
}

/* Menu item styling */
.menu-item {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 15px 20px;
  font-size: 0.9rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  transition:
    background-color 0.3s ease,
    color 0.2s ease;
}

.menu-item:hover,
.menu-item:focus {
  color: #1e79cc;
  outline: none;
}

/* Active menu item styling */
.menu-item.active {
  background-color: #c2bfc9; /* Active background color */
  color: #fff; /* Active text color */
  font-weight: bold; /* Optionally make it bold */
  border-right: 4px solid #eefbfd; /* Highlight border on the right */
}

/* Header section styling */
.menu-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  color: #fff;
  text-align: center;
}

.menu-header h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
  color: #333;
  font-family: "Plus Jakarta Sans", sans-serif;
}

/* Responsive styling */
@media (max-width: 600px) {
  .menu-sidebar {
    width: 200px;
  }

  .hamburger-icon {
    width: 30px;
    height: 20px;
  }
}
