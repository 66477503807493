.delivery-address-card-container {
  padding: 9px;
  border-radius: 8px;
  margin-top: 10%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0d0d0;
  background-color: #fff;
  font-family: Plus Jakarta Sans;
}

.delivery-address-card-header-container {
  display: flex;
  padding: 0 2%;
}

.delivery-address-card-header-left-container {
  display: flex;
  width: 80%;
}

.delivery-address-card-header-right-container {
  display: flex;
  justify-content: center;
  width: 20%;
}

.home-svg-container {
  align-self: center;
  margin-right: 3%;
}

.arrow-right-svg-container {
  align-self: flex-end;
}

.home-svg,
.arrow-right-svg {
  width: 2rem;
  /* Adjust based on your design */
  height: 2rem;
  color: #333;
  /* Set the color of the SVG */
}

.delivery-address-card-header-text {
  font-family: Plus Jakarta Sans;
  font-size: medium;
  color: #333;
  /* margin-top: 4px; */
  font-weight: 700;
}

.delivery-address-card-body-container {
  padding: 2% 2%;
}

.buyer-name-text {
  font-family: Plus Jakarta Sans;
  font-size: medium;
  font-weight: 400;
  margin-bottom: 1%;
  align-self: flex-start;
}

.delivery-address-text {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  color: #7b7589;
  align-self: flex-start;
  font-weight: 400;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.estimated-delivery-text {
  font-family: Plus Jakarta Sans;
  font-size: 3.6vw;
  color: #ff6f61;
  font-weight: 600;
  margin-bottom: 1%;
}

.change-button {
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  font-family: Plus Jakarta Sans;
  cursor: pointer;
  font-size: small;
  font-weight: 700;
  color: #1e799c;
  margin-right: 4px;
  position: relative;
}
