.recommendation-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-family: Plus Jakarta Sans;
}

.recommended-product {
  width: 240px;
  height: 400px;
  margin: 14px;
  border-radius: 25px;
  font-family: Plus Jakarta Sans;
}

/* Base styles */
.recommended-product-card {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

/* Image container */
.recommended-card-image {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.recommended-card-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  height: 19rem;
  border-bottom: 1px solid #e5e7eb;
  /* Light gray */
}

/* Discount badge */
.recommended-discount-badge {
  position: absolute;
  top: 10px;
  right: -5px;
  background: #e63946;
  /* Red */
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
}

/* Card content */
.recommended-card-content {
  font-family: Plus Jakarta Sans;
}

.recommended-rating {
  position: absolute;
  top: 90%;
  padding: 4px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.6rem;
  background: #f3f4f6;
  border-radius: 12px;
}

.recommended-rating-value {
  color: black;
  /* Yellow */
  font-weight: bold;
}

.recommended-reviews {
  color: #6b7280;
  /* Gray */
}

.recommended-product-name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.recommended-product-description {
  font-size: 0.7rem;
  color: #6b7280;
  margin: 0;
  margin-bottom: 0.5rem;
}

.recommended-price-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.recommended-price {
  font-weight: bold;
  color: #333;
  /* Blue */
}

.recommended-original-price {
  text-decoration: line-through;
  color: #9ca3af;
  /* Light gray */
  font-size: 0.9rem;
}

.recommended-heading {
  display: flex;
  /* gap: 0.85rem; */
  align-items: center;
  margin-left: 2rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.recommended-heading span {
  font-size: 2.25rem;
  font-weight: bold;
  font-style: italic;
  font-family: "Jakarta", sans-serif;
}

#recommended-hastag {
  color: #9ca3af;
  /* font-size: 4.25rem; */
}

.recommended-heading span:first-child {
  color: #9ca3af;
  /* gray-400 */
}

.recommended-heading span:last-child {
  color: #1e799c;
  margin-left: 6px;
  /* #1E799C */
}

/* Responsive styles */
@media (max-width: 768px) {
  /* For tablet and smaller screens */
  .recommended-product-card {
    max-width: 90%;
    /* Reduce card width */
    margin: 0 auto;
    /* Center the card */
  }

  .recommended-card-content {
    padding: 0.8rem;
  }

  .recommended-product-name {
    font-size: 12px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recommended-product-description {
    font-size: 11px;
    white-space: nowrap;
    overflow-x: scroll;
    /* text-overflow: ellipsis;  */
    width: 100%;
    max-width: 100%;
    display: block;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .recommended-product-description::-webkit-scrollbar {
    display: none; /* For WebKit-based browsers like Chrome, Safari */
  }
  .recommended-price-section {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .recommended-product-card {
    max-width: 100%;
    /* Use full width */
    box-shadow: none;
    /* Remove shadow for simplicity */
  }

  .recommended-card-content {
    padding: 0.5rem;
  }

  .recommended-product-name {
    font-size: 12px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recommended-product-description {
    font-size: 11px;
  }

  .recommended-price-section {
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 0.3rem;
  }

  .recommended-discount-badge {
    font-size: 0.7rem;
    padding: 3px 6px;
  }
}

@media only screen and (max-width: 568px) {
  .recommended-card-image img {
    width: 100%;
    height: 14rem;
  }

  .recommended-product {
    width: 160px;
    margin: 5px;
    height: 309px;
  }

  .recommended-heading span {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    font-family: Plus Jakarta Sans;
  }
}
