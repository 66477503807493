.bottom-sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10000;
  transition: opacity 0.3s ease;
}

.bottom-sheet-overlay.open {
  display: flex;
  opacity: 1;
}

.bottom-sheet {
  background: white;
  width: 100%;
  max-height: 80%;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
}

.bottom-sheet.open {
  transform: translateY(0);
}

.bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.bottom-sheet-header-text {
  color: black;
  font-size: 20px;
  font-weight: 700;
  font-family: Plus Jakarta Sans;
}

.close-button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.bottom-sheet-content {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1; /* Allow content to take available space */
}

.bottom-sheet-footer {
  background-color: white;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .bottom-sheet-overlay {
    min-width: 0;
    margin-left: 0;
  }
}
