.display-carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 100%;
  align-items: center;
}

.display-carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: transparent;
  border: none;
  cursor: pointer;
}

.display-carousel-button-left {
  left: 10px;
}

.display-carousel-button-right {
  right: 10px;
}

.display-carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
}

.display-carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.display-carousel-dot {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background-color: #eefbfdb2;
  cursor: pointer;
}

.display-carousel-dot.active {
  width: 15px;
  background-color: #ffffff;
}
