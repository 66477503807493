.App {
  background: #fff;
  color: #212121;
  font-family: "Plus Jakarta Sans", sans-serif;
  /* display: grid; */
  /* place-items: center; */
}

input[type="checkbox"] {
  accent-color: #1e799c;
}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.text-truncate2 {
  display: -webkit-box; /* Enables multiline truncation */
  -webkit-box-orient: vertical; /* Vertical orientation for text flow */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow */
  -webkit-line-clamp: 2; /* Limits the content to 2 lines */
  line-height: 1.5; /* Set line height for uniform spacing */
  min-height: calc(1.5em * 2); /* Reserve space for exactly 2 lines */
  max-width: fit-content;
}

code {
  font-family: "Plus Jakarta Sans", sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
/* .joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 10px;
} */

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full viewport height */
}

.centeredContainerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Full viewport height */
}

.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 25px;
}

.joinChatContainerss {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.joinChatHeading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
}
.joinChatSubHeading {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: grey;
}

.joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.joinChatContainer input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.joinChatContainerss input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #f5f2fc;
  color: #9ea2b3;
  cursor: pointer;
}

.joinChatContainerss button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
}

.joinChatContainer button:hover {
  background: #1e799c;
  color: white;
}

.joinChatContainerss button:hover {
  background: #1e799c;
  color: white;
}

.BrandHeading {
  color: #1e799c;
  font-size: 32px;
  font-weight: bold;
  /* margin-top: -70px; */
}

.BrandHeadingBorder {
  height: 0px;
  width: 100%;
  /* margin-top: -120px; */
}
.chat-window {
  /* width: 300px;
  height: 420px; */
}

.chat-window p {
  margin: 0;
}
.chat-window .chat-header {
  height: 45px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}
.chat-window .chat-header p {
  display: block;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body {
  height: 72vh;
  border-top: 1px solid #263238;
  background: #fff;

  position: relative;
}
.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 120px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.chat-window .chat-footer {
  height: 40px;
  border: 1px solid #cfc4e9;
  border-radius: 8px;
  display: flex;
}

.chat-window .chat-footer input {
  height: 100%;
  flex: 85%;
  border: 0;
  padding: 0 0.7em;
  font-size: 1em;

  outline: none;
  font-family: "Open Sans", sans-serif;
}

.chat-window .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 25px;
  color: lightgray;
}

.chat-window .chat-footer button:hover {
  color: #9ea2b3;
  background-color: #f5f2fc;
}
.hide {
  opacity: 0 !important;
}

/* App.css */
.joinChatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh; /* Full viewport height to center vertically */
  text-align: center; /* Center text inside the container */
  box-shadow: 3px 5px 22px 12px #f5f2fc;
  min-width: 40vw;
}

.joinChatContainerss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 60vh; */
  text-align: center; /* Center text inside the container */
  box-shadow: 3px 5px 22px 12px #f5f2fc;
}

.joinChatContainerss input,
.joinChatContainerss button {
  margin: 10px 0; /* Add some margin for spacing */
  padding: 10px; /* Add padding for better appearance */
  width: 80%; /* Set a width for the input and button */
  max-width: 400px; /* Set a maximum width */
}

.joinChatContainer input,
.joinChatContainer button {
  margin: 10px 0; /* Add some margin for spacing */
  padding: 10px; /* Add padding for better appearance */
  width: 80%; /* Set a width for the input and button */
  max-width: 400px; /* Set a maximum width */
}

.joinChatHeading,
.joinChatSubHeading {
  margin-bottom: 20px; /* Add some margin below the headings */
}

.stylingList {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-around;
}

.inputWithCountryCode {
  display: flex;
  align-items: center;
}

.countryCode {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
}

.inputWithCountryCode input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 16px;
}

/* Styles for small screens (mobile) */
@media (max-width: 550px) {
  .centeredContainer {
    max-width: none;
  }

  .joinChatContainerss {
    max-width: none;
    width: 80vw;
  }
}

/* Styles for small screens (mobile) */
@media (min-width: 550px) {
  .joinChatContainerss {
    min-width: 550px;
  }
}

.main-wrapper {
  position: relative;
  width: 100%;
  max-width: 450px;
  margin: auto;
}
