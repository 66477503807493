.order-list-heading {
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 5%;
  margin-left: 5%;
}

.Orders-Page-No-Orders {
  display: grid;
  place-items: center;
  width: 100%;
  height: 80vh;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.05));
}

.pagination-container {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.pagination-content {
  display: flex;

  gap: 5px;
}
