.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.skeleton-card {
  background-color: white;
  margin-bottom: 4%;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.05));
  /* padding-top: 3%;
      padding-bottom: 3%;
      padding-left: 3%; */
  padding: 3%;
}

.skeleton-img-top {
  height: 3rem;
  width: 3rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.order-status-card-container-skeleton {
  width: 94%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 5%;
}

.order-details-page-skeleton-text-h1 {
  left: 0;
  font-size: larger;
  width: 40%;
  height: 8px;
  margin-bottom: 0.25rem;
  background-color: hsl(200, 20%, 70%);
}
.order-details-page-skeleton-text-h2 {
  font-size: large;
  width: 90%;
  height: 5px;
  margin-bottom: 0.25rem;
  background-color: hsl(200, 20%, 70%);
}

.order-details-page-skeleton-text-h3 {
  font-size: large;
  width: 25%;
  height: 3px;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  background-color: hsl(200, 20%, 70%);
}

.order-details-page-skeleton-text-h4 {
  font-size: large;
  width: 35%;
  height: 5px;
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
  background-color: hsl(200, 20%, 70%);
}

.order-details-skeleton-text {
  width: 9rem;
  height: 4px;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.orders-page-quantity-box {
  border-radius: 8px;
  height: 1.5rem;
  width: 6rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.orders-page-details-quantity-box-skeleton {
  border-radius: 8px;
  height: 0.2rem;
  width: 3rem;
  background-color: hsl(
    200,
    20%,
    70%
  ); /* Set initial background color to match animation start */
}

.orders-page-details-button-container-skeleton {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.orders-page-details-quantity-container-skeleton {
  margin-right: 2%;
}
