.orders-page-card {
  background-color: white;
  margin-bottom: 4%;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.05));
  /* padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%; */
  padding: 3%;
}

.orders-page-card-header {
  display: flex;
  width: full;
  height: 3rem;
}

.unfulfilled-svg-container {
  position: relative; /* Allows absolute positioning of children */
  height: 100%;
  justify-content: start;
}
.tick-svg-container {
  position: absolute; /* Position it absolutely */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right */
  width: 40%; /* Adjust size as needed */
  height: 60%; /* Adjust size as needed */
  display: flex; /* Optional: center the TickSvg if needed */
  justify-content: center; /* Optional: center horizontally */
  align-items: center; /* Optional: center vertically */
}

.orders-page-header-text {
  padding-top: 2%;
  width: full;
  margin-left: 5%;
}

.orders-page-header {
  font-weight: bold;
  width: 100%;
}

.orders-page-product-container {
  display: flex;
  margin-top: 2%;
  height: 7rem;
}

.orders-page-left-container {
  width: 24%;
}

.orders-page-image {
  border-radius: 8px;
  width: 100%;
  height: 7rem;
}

.orders-page-right-container {
  padding-left: 5%;
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 1%;
}

.orders-page-name {
  font-weight: bold;
  font-size: 16px; /* Adjust as necessary */
  line-height: 1.5; /* Adjust line height for readability */
  overflow: hidden; /* Hide overflowed content */
  display: block;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust this to fit the content of 2 lines */
}

.orders-page-description {
  color: #757575;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-page-button-container {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.orders-page-quantity-container {
  margin-right: 2%;
}

.orders-page-dropdown-button {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0.28rem;
  text-align: center;
  margin-left: 6px;
}
