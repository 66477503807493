.sale-products-container {
  margin-top: 7%;
}

.sale-products-title {
  color: #111827;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.sale-product-container {
  border-radius: 0.75rem;
  border: 0.5px solid #f1f1f3;
  background: #fff;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.05));
  margin-bottom: 3%;
}

.sale-products-body {
  display: grid;
  grid-template-columns: 42% 53%;
  gap: 5%;
}

.sale-product-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.sale-product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.sale-product-image-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.sale-product-item-header,
.sale-product-item-body,
.sale-product-item-footer {
  margin-bottom: 5%;
  margin-top: 3%;
  display: flex;
  align-items: center;
}

.sale-product-item-header {
  flex-direction: column;
  place-items: start;
}

.sale-product-title {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  margin: 3% 0 0 0;
}

.sale-product-subtitle {
  color: #38353f;
  font-family: Plus Jakarta Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

/* .sale-product-item-body {
  display: grid;
  grid-template-columns: 50% 40%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5%;
} */

.sale-product-cost-price {
  color: #938e9e;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  margin-right: 2px;
  font-weight: 400;
  text-decoration: line-through;
}

.sale-product-selling-price {
  color: #222026;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.sale-product-discount {
  color: #1e799c;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 5% 0 5% 1%;
}

.sale-product-button-container {
  border-radius: 8px;
  border: 1px solid var(--Calypso-600, #1e799c);
  padding: 0.45rem;
  background: var(--Calypso-600, #1e799c);
  margin-right: 5%;
}

.sale-product-button-container:disabled {
  background: #b0e5f1;
  border: 1px solid #d5f2f8;
  color: #eefbfd;
}

.sale-product-secondary-button-container {
  border-radius: 8px;
  border: 1px solid var(--Calypso-600, #1e799c);
  padding: 0.45rem;
  background: white;
}

.sale-product-secondary-button-container:disabled {
  background: #b0e5f1;
  border: 1px solid #d5f2f8;
  color: #eefbfd;
}

.sale-product-button-text {
  color: #fff;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 0, 875rem;
  font-style: normal;
  font-weight: 500;
}

.sale-product-secondary-button-text {
  color: #1e799c;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 0, 875rem;
  font-style: normal;
  font-weight: 500;
}
