.countdown-container {
  margin-left: 5%;
  margin-top: 10%;
  position: absolute;
  background-color: #00000040;
  padding: 6px;
  border-radius: 6px;
  top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countdown-timer {
  display: inline-block;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-weight: 500;
}
.timer-count {
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 500;
}

.view-count {
  padding: 2px 4px 2px 4px;
  gap: 6px;
  border-radius: 6px;
  background: #00000040;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.live {
  border-radius: 6px;
  background: #d9342b;
  text-align: center;
  padding: 6px 12px 6px 12px;
  gap: 6px;
}

.live-text {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .countdown-container {
    top: 0rem;
    margin-left: 3%;
  }
}
