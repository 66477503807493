/* Overlay */
.category-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
.category-popup-overlay-desktop {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  /* display: flex; */
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
.category-popup-overlay.close {
  opacity: 0;
  pointer-events: none; /* Disable interactions when closing */
}

/* Popup Content */
.category-popup-content {
  background: white;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  padding-bottom: 100px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

/* Slide-Up Animation */
.category-slide-up {
  animation-name: slide-up;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide-Down Animation */
.category-slide-down {
  animation-name: slide-down;
}

@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* Header (Title and Close Button) */
.category-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.category-popup-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

/* Close Button */
.category-popup-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* List */
.category-popup-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}

.category-popup-item {
  margin: 12px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.category-popup-item:hover {
  text-decoration: underline;
}

/* Back Button */
.category-back-button {
  background: none;
  border: none;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
  color: #333;
}

.category-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-popup-title {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* Slide Animations */
.category-popup-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

.category-popup-list-enter {
  animation-name: slide-in;
}

.category-popup-list-exit {
  animation-name: slide-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* Error and Empty State */
.category-popup-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.category-popup-empty {
  color: #666;
  font-size: 14px;
  text-align: center;
}

/* List Wrapper for Animation */
.category-popup-list-wrapper {
  position: relative;
  overflow: hidden;
  height: auto; /* Adjust height as needed */
}

.category-popup-list {
  list-style: none;
  padding: 0;
  margin: 0;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.category-popup-list-wrapper.slide-out {
  animation: slide-out 0.3s ease;
}

.category-popup-list-wrapper.slide-in {
  animation: slide-in 0.3s ease;
}

@keyframes slide-out {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

/* Error and Empty States */
.category-popup-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.category-popup-empty {
  color: #666;
  font-size: 14px;
  text-align: center;
}
