:root {
  /* full screen width */
  --full-screen-width: 100vw;

  /* Base Colors */
  --white: #ffffff;
  --black: #000000;

  /* Gray Shades */
  --gray-50: #f6f6f6;
  --gray-100: #eeeeee;
  --gray-200: #e2e2e2;
  --gray-300: #c8c8c8;
  --gray-400: #afafaf;
  --gray-500: #6b6b6b;
  --gray-600: #545454;
  --gray-700: #333333;
  --gray-800: #1f1f1f;
  --gray-900: #141414;

  /* Violet Shades */
  --violet-50: #e6e7ef;
  --violet-100: #c1c2d7;
  --violet-200: #9a9bbc;
  --violet-300: #7475a1;
  --violet-400: #484599;
  --violet-500: #433b8c;
  --violet-600: #38317a;
  --violet-700: #342d6a;
  --violet-800: #2d245d;
  --violet-900: #201547;

  /* Yellow Shades */
  --yellow-50: #fffde6;
  --yellow-100: #fff9c2;
  --yellow-200: #fef599;
  --yellow-300: #fdf16f;
  --yellow-400: #fbec4b;
  --yellow-500: #f9e723;
  --yellow-600: #fdd924;
  --yellow-700: #fcc01a;
  --yellow-800: #fba80f;
  --yellow-900: #f97d00;
  --yellow-1000: #f6f6f6;

  /* Red Shades */
  --red-50: #fef3f2;
  --red-100: #fee4e2;
  --red-200: #fecdca;
  --red-300: #fda29b;
  --red-500: #f04438;
  --red-600: #d92d20;
  --red-700: #b32318;
  --red-800: #912018;
  --red-900: #7a271a;

  /* Green Shades */
  --green-50: #ecfdf3;
  --green-100: #d1fadf;
  --green-200: #a6f4c5;
  --green-300: #6ce9a6;
  --green-400: #32d583;
  --green-500: #12b76a;
  --green-600: #039855;
  --green-700: #027948;
  --green-800: #05603a;
  --green-900: #054f31;

  /* Opacity */
  --opacity-light: rgba(255, 255, 255, 0.5);
  --opacity-dark: rgba(0, 0, 0, 0.5);

  /* Semantic Colors - Surface */
  --surface-primary: var(--white);
  --surface-secondary: var(--gray-50);
  --surface-secondary-hover: var(--gray-100);
  --surface-secondary-pressed: var(--gray-200);
  --surface-tertiary: var(--gray-100);
  --surface-inverted: var(--gray-900);
  --surface-inverted-variant: var(--gray-700);
  --surface-action1: var(--opacity-dark);
  --surface-action2: var(--yellow-600);
  --surface-action2-hover: var(--yellow-700);
  --surface-action2-pressed: var(--yellow-800);
  --surface-status: var(--yellow-900);
  --surface-screen-dark: var(--opacity-dark);
  --surface-screen-light: var(--opacity-light);
  --surface-disabled: var(--yellow-1000);
  --surface-destructive: var(--red-600);
  --surface-action-2-action-2-disabled: #f6f6f6;
  /* Semantic Colors - Content */
  --content-primary: var(--gray-900);
  --content-secondary: var(--gray-500);
  --content-inverted: var(--gray-50);
  --content-inverted-variant: var(--gray-100);
  --content-inverted-action: var(--yellow-100);
  --content-active: var(--violet-600);
  --content-action: var(--violet-400);
  --content-action-focused: var(--violet-300);
  --content-action-hover: var(--violet-500);
  --content-inactive: var(--gray-400);
  --content-hover: var(--gray-200);
  --content-destructive: var(--red-700);
  --content-success: var(--green-600);

  /* Semantic Colors - Border */
  --border-primary: var(--gray-200);
  --border-action: var(--gray-900);

  /* Gradient Tokens */
  --gradient-dark: linear-gradient(180deg, #fff0, #1a1a1ab8 74%);

  /* radiu tokens */
  --radius-small: 4px;
  --radius-medium: 8px;
  --radius-large: 16px;
  --radius-xlarge: 24px;
  --radius-xxlarge: 120px;
  --radius-round: 50%;

  /* spacing tokens */
  --spacingx0-5: 2px;
  --spacingx1: 4px;
  --spacingx1-6: 6px;
  --spacingx2: 8px;
  --spacingx3: 12px;
  --spacingx4: 16px;
  --spacingx5: 20px;
  --spacingx6: 24px;
  --spacingx7: 28px;
  --spacingx8: 32px;
  --spacingx9: 36px;
  --spacingx10: 40px;
  --spacingx11: 44px;
  --spacingx12: 48px;

  /* label */
  --label-small: 12px;
  --label-medium: 14px;
  --label-large: 16px;
  --label-xlarge: 20px;

  /* font weight*/
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;

  /* line height */
  --line-height-small: 16px;
  --line-height-medium: 20px;
  --line-height-large: 24px;
  --line-height-xlarge: 28px;

  --font-family-inter: Inter;
}
