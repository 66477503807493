.login-phone-otp-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  font-family: var(--font-family-inter);
}

.login-phone-otp-header-text {
  overflow: hidden;
  color: var(--content-primary);
  text-overflow: ellipsis;
  font-size: var(--label-large);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-large);
  padding: var(--spacingx3) var(--spacingx4);
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  font-family: var(--font-family-inter);
}

.login-phone-otp-input-container {
  font-family: var(--font-family-inter);
  display: flex;
  flex-direction: row;
  gap: var(--spacingx3);
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  font-family: var(--font-family-inter);
}

.login-phone-otp-input {
  font-family: var(--font-family-inter);
  display: flex;
  flex-direction: row;
  padding: var(--spacingx2);
  max-width: calc(var(--spacingx2) * 3);
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-primary);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--content-primary);
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  font-family: var(--font-family-inter);
}

.login-phone-otp-input:focus {
  border: 1px solid var(--black);
}

.login-phone-otp-resend-text-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: var(--white);
  margin-top: var(--spacingx4);
  margin-bottom: var(--spacingx2);
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  font-family: var(--font-family-inter);
}

.login-phone-otp-resend-text-container:disabled {
  color: var(--content-inactive);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  border: none;
  cursor: pointer;
  background-color: var(--white);
  font-family: var(--font-family-inter);
}

.login-phone-otp-resend-text {
  color: var(--content-secondary);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  text-decoration: underline;
  text-underline-offset: var(--spacingx1);
  font-family: var(--font-family-inter);
}

.login-phone-otp-resend-text.disabled {
  color: var(--content-inactive);
}

.login-phone-otp-resend-text-time {
  margin-left: var(--spacingx2);
  color: var(--content-inactive);
  font-size: var(--label-small);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  text-decoration: none;
  font-family: var(--font-family-inter);
}
.login-phone-otp-continue-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding-left: var(--spacingx4);
  padding-right: var(--spacingx4);
  padding-bottom: var(--spacingx3);
  padding-top: var(--spacingx3);
  font-family: var(--font-family-inter);
}
.login-phone-otp-continue-button {
  display: flex;
  width: calc(var(--full-screen-width) - 2 * var(--spacingx4));
  padding: var(--spacingx4) var(--spacingx8);
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: var(--radius-large);
  background: var(--surface-inverted);
  border: none;
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-inverted);
}

.login-phone-otp-continue-button:disabled {
  background: var(--surface-action-2-action-2-disabled);
  color: var(--content-inactive);
  font-family: var(--font-family-inter);
}
