.bottom-sheet-v2-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1000;
}

.bottom-sheet-v2-overlay.open {
  display: flex;
  align-items: flex-end;
}

.bottom-sheet-v2 {
  background: var(--surface-primary);
  width: 100%;
  border-radius: 12px 12px 0 0;
  position: relative;
  transform: translateY(100%);
  transition:
    transform 0.3s ease,
    height 0.3s ease;

  font-family: var(--font-family-inter);
  z-index: 1001;
}

.bottom-sheet-v2.open {
  transform: translateY(0);
}

.bottom-sheet-v2.dragging {
  transition: none;
}

.bottom-sheet-v2-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: var(--font-family-inter);
}

.dragbar-container {
  width: calc(100% - 2 * var(--spacingx2));
  padding: var(--spacingx2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  user-select: none;
}

.drag-bar {
  width: 32px;
  height: 4px;
  background: var(--content-primary);
}

.bottom-sheet-v2-content {
  overflow-y: auto;
  height: fit-content;
  font-family: var(--font-family-inter);
}

@media only screen and (max-width: 1000px) {
  .bottom-sheet-v2-overlay {
    min-width: 0;
    margin-left: 0;
  }
}
