.order-details-container {
  border: 1px solid #e5e5e8;
  padding: 8px 16px 12px 16px;
  border-radius: 12px;
}

.order-details-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.order-details-title-text {
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-weight: 600;
  color: #222026;
  text-align: left;
}

.order-details-item-text {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #7b7589;
}

.order-details-final-text {
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  color: #7b7589;
}
