.apply-coupon-page {
  /* padding: 16px; */
  font-family: Arial, sans-serif;
}

.apply-coupon-header {
  margin-bottom: 16px;
}

.apply-coupon-header h5 {
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.apply-coupon-header p {
  color: #222026;
  margin: 4px 0 0;
  font-size: 12px;
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  line-height: 16px;
}

.apply-coupon-input {
  display: flex;
  gap: 8px;

  border: 1px solid #d5d4dc;
  border-radius: 8px;
}

.apply-coupon-input input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
}

.apply-coupon-input button {
  padding: 8px 16px;
  background: none;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* .apply-coupon-input button:hover {
    background-color: #1e40af;
} */

.apply-coupon-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  padding: 16px;
  background-color: #f3f4f4;
}

.coupon-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  /* border: 1px solid #e5e7eb; */
  border-radius: 8px;
  background-color: #ffffff;
}

.coupon-card h3 {
  font-size: 16px;
  margin: 0 0 4px;
}

.coupon-card p {
  margin: 2px 0;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  color: #938e9e;
  font-size: 12px;
}

.coupon-card button {
  /* padding: 8px 16px; */
  background: none;
  color: #938e9e;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 44px;
}

.coupon-card span {
  margin: 2px 0;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  color: #6b7280;
  font-size: 12px;
}

.apply-coupon-header {
  display: flex;
  margin-bottom: 16px;
  gap: 8px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.back-arrow {
  font-size: 20px;
  /* Adjust size as needed */
  cursor: pointer;
}

.header-left h5 {
  font-size: 18px;
  margin: 0;
}

.cart-value {
  font-size: 14px;
  color: #6b7280;
  margin: 0;
}

.coupon-info h3 {
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.error-text {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 500;
  color: #c83532;
  margin: 0;
  display: flex;
  padding-left: 16px;
  align-items: center;
  line-height: 24px;
}
