.store-cards-heading {
  display: flex;
  /* gap: 0.85rem; */
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.store-cards-heading span {
  font-size: 2.25rem;
  font-weight: bold;
  font-style: italic;
  font-family: "Jakarta", sans-serif;
}

#store-cards-heading {
  color: #9ca3af;
  font-size: 4.25rem;
}

.store-cards-heading span:first-child {
  color: #9ca3af;
  /* gray-400 */
}

.store-cards-heading span:last-child {
  color: #1e799c;
  margin-left: 8px;
}

.store-cards-container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  font-family: "Plus Jakarta Sans";
  padding-left: 8px;
  gap: 16px;
}

.store-card {
  width: 276px;
  margin: 14px;
  border-radius: 25px;
  font-family: Plus Jakarta Sans;
}

.recommended-store-card {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.recommended-store-card-image {
  position: relative;
  width: 100%;
  /* height: 10rem; */
  border-radius: 12px;
}

.coverImage img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 12px;
}

.store-logo img {
  /* width: 100%; */
  height: 2.5rem;
  object-fit: cover;
  border-radius: 25px;
}

.recommended-store-card-rating {
  padding: 4px;
  /* margin-left: 12px; */
  display: flex;
  /* align-items: center; */
  /* gap: 0.5rem; */
  font-size: 0.8rem;
  flex-direction: column;
  border-radius: 12px;
}

.recommended-store-card-rating-value {
  color: white;
  /* Yellow */
  font-size: smaller;
  /* font-weight: bold; */
}

.recommended-store-card-reviews {
  color: #6b7280;
  /* Gray */
}

.recommended-store-card-content {
  margin-top: 6px;
  /* align-items: center; */
  margin-left: -2px;
  border-radius: 11px;
  position: absolute;
  top: 61%;
  padding: 4px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  color: white;
  font-family: Plus Jakarta Sans;
  background: linear-gradient(180deg, #ffffff00, #1a1a1ab8 74%);
  width: 97%;
  border-radius: 10px;
}

.recommended-store-name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.store-logo {
  height: 50px;
  border-radius: 25px;
}

@media (max-width: 768px) {
  /* For tablet and smaller screens */
  .recommended-store-card {
    /* max-width: 90%; */
    /* Reduce card width */
    margin: 0 auto;
    /* Center the card */
    flex: 1 1 calc(25% - 16px);
  }

  .store-card {
    flex: 1 1 calc(25% - 16px); /* Cards take 25% width, 4 cards per row */
  }

  .recommended-store-card-content {
    padding: 0.8rem;
  }

  .recommended-store-name {
    font-size: 1rem;
  }

  /* .recommended-product-description {
    font-size: 0.85rem;
  } */

  .recommended-price-section {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .recommended-store-card {
    /* max-width: 100%; */
    box-shadow: none;
    flex: 1 1 100%;
  }

  .store-card {
    flex: 1 1 100%; /* Full width cards on extremely small screens */
  }

  .recommended-store-card-content {
    padding: 0.5rem;
    top: 62%;
    margin-left: 0px;
    width: 92%;
  }

  .recommended-store-name {
    font-size: 0.95rem;
  }

  /* .recommended-product-description {
    font-size: 0.8rem;
  } */

  .recommended-price-section {
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 0.3rem;
  }

  .recommended-discount-badge {
    font-size: 0.7rem;
    padding: 3px 6px;
  }
}

@media only screen and (max-width: 568px) {
  .recommended-store-card-image {
    width: 100%;
    height: 14rem;
  }

  .store-cards-heading span:last-child {
    margin-left: 2px;
    /* #1E799C */
  }

  .store-card {
    width: 150px;
    /* height: 309px; */
    min-width: 200px;
  }

  .store-cards-heading {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    margin-left: 1rem;
    margin-top: 3rem;
    margin-bottom: 0;
  }

  .store-cards-heading span {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    font-family: "Jakarta", sans-serif;
  }

  .coverImage img {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 12px;
  }
}

@media (max-width: 767px) {
  .store-cards-container {
    display: flex;
    flex-wrap: nowrap; /* Keep items in a single row (no wrapping) */
    overflow-x: auto; /* Horizontal scroll if needed */
    padding: 8px; /* Optional padding for mobile */
  }

  .store-card {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row on mobile */
    margin-bottom: 16px; /* Optional: space between rows */
  }

  /* Optional: You can add additional small screen breakpoints if needed */
  @media (max-width: 480px) {
    .store-card {
      flex: 1 1 100%; /* Full width cards on extremely small screens */
    }
  }
}
