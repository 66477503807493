/* OrderStatusDropdownCard.css */
.order-status-card-container {
  width: 96%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  border: 1px solid #e0e0e0;
  padding-top: 5%;
  padding-bottom: 5%;
}
.order-status-card-header {
  width: 100%;
  font-size: larger;
  font-weight: bold;
}

.order-status-card-order-id {
  margin-top: 1%;
  font-size: medium;
  font-weight: 100;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.order-status-card-buyer-name {
  margin-top: 3%;
  width: 100%;
  font-size: large;
  font-weight: bold;
}

.order-status-card-buyer-address {
  margin-top: 1%;
  font-size: medium;
  font-weight: 100;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.order-status-dropdown-card-header-left {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: 3%;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: medium;
  font-weight: bold;
  gap: 10px;
}

/* Dropdown Content */
.order-status-card-dropdown-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  border-width: 0px;
}

.order-status-card-dropdown-content-left-container {
  display: flex;
  align-items: center;
}

.order-status-card-dropdown-content-left-svg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-status-card-vertical-line {
  width: 2px;
  height: 30px;
  background-color: #888;
}
.order-status-card-vertical-line-pending {
  width: 2px;
  height: 30px;
  background-color: #1e799c; /* Adjust this to your neutral10 color */
}

/* Right Section */
.order-status-card-dropdown-content-right-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  padding-left: 20px;
}

.order-status-card-dropdown-content-right-text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.order-status-card-dropdown-content-right-row-one-text {
  font-size: 14px;
  font-weight: bold;
}

.order-status-card-dropdown-content-right-row-two-text {
  font-size: 12px;
  color: #888;
}
.order-status-card-dropdown-content {
  width: 90%;
  top: 100%; /* Adjust based on where you want it to appear */
  left: 0;
  background-color: white;
  z-index: 1;
  padding: 5%;
}

.order-status-card-dropdown-content.open {
  display: block; /* Show when open */
}
