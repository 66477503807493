.product-card {
  width: 50%;
  border-radius: 8px;
  background: #000000a6;
  display: flex;
  padding: 5%;
  justify-content: space-between;
  align-items: center;
}
.productcard-image {
  width: 20%;
  height: 100%;
  border-radius: 8px;
}

.productcard-details {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.product-details p {
  font-size: small;
  margin: 1px;
  font-family: Plus Jakarta Sans;
}
.buy-button {
  width: 40%;
  padding: 0.5rem;
  border-radius: 8px;
  background: #1e799c;
  color: white;
  border-color: #1e799c;
  cursor: pointer;
  border: 0;
  font-family: Plus Jakarta Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.3));
}

.buy-button:disabled {
  opacity: 0.5;
}

.productcard-details-item {
  font-family: Plus Jakarta Sans;
  color: white;
}

.payment-spinner {
  width: 1rem;
  height: 1rem;
  border: 1px solid #1e799c;
  border-top: 1px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buy-now-no-button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: white;
  color: #dc3545;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  border: 1px solid #dc3545;
}

.buy-now-yes-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #1e799c;
  color: white;
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  text-align: center;
}
.buy-now-content-container {
  padding: 0% 5% 5% 5%;
}

.product-card-sold-out-strip {
  background-color: red;
  width: 40%;
  color: white;
  padding: 0.5rem;
  font-weight: normal;
  border-radius: 8px;
  font-family: Plus Jakarta Sans;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  .product-card {
    width: 85%;
  }
}
