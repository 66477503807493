.seller_store_card_container {
  /* width: 100%; */
  padding: 9px 9px 0px 9px;
  border-radius: 8px;
  margin-top: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0d0d0;
  background-color: #fff;
  font-family: Plus Jakarta Sans;
}

.seller_store_card_header_container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 2px;
  flex-direction: column;
}

.seller_store_card_header_left_container {
  display: flex;
  align-items: center;
}

.seller_store_image_container {
  margin-right: 12px;
}

.seller_store_name_container {
  flex-direction: column;
}

.seller_store_name_text {
  font-weight: bold;
  font-size: 16px;
}

.product_container {
  display: flex;
  margin-bottom: 16px;
}

.product_left_container {
  width: 25%;
}

.product_right_container {
  width: 75%;
  padding-left: 16px;
}

.image {
  width: 100%;
  height: auto;
}

.name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.description {
  font-size: 12px;
  margin-bottom: 8px;
}

.price_text {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.mrp_text {
  font-size: 12px;
  color: #999;
}

.discount_text {
  font-size: 12px;
  color: #e74c3c;
}

.bottom_button_container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.move_to_wishlist_button {
  padding: 12px 24px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.bottom_button_text {
  font-size: 16px;
  font-weight: bold;
}

.cart-product-container {
  display: flex;
  overflow: hidden;
  margin-top: 2%;
  font-family: Plus Jakarta Sans;
}

.cart-product-left-container {
  width: 25%;
}

.cart-product-left-container img {
  border-radius: 8px;
  width: 100%;
  height: 7rem;
}

.cart-product-right-container {
  width: 75%;
  padding-left: 16px;
  margin-left: 10px;
}

.cart-image-container {
  width: 100%;
  height: 100%;
}

.MultipleProductCart-product-image {
  width: 25vw;
  height: 12vh;
  border-radius: 8px;
  margin-bottom: 7px;
}

.MultipleProductCart-product-name {
  font-size: 16px;
  font-weight: bold;
  font-family: Plus Jakarta Sans;
}

.MultipleProductCart-product-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  font-family: Plus Jakarta Sans;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.MultipleProductCart-price-text {
  font-size: 16px;
  font-weight: bold;
  /* margin: 2px; */
  margin-top: 5px;
}

.mrp-text {
  font-size: 14px;
  color: #999;
}

.line-through {
  text-decoration: line-through;
}

.discount-text {
  font-size: 14px;
  color: #e74c3c;
}

.product-name-v2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0px;
  font-family: Plus Jakarta Sans;
}

.product-image-v2 {
  width: 25vw;
  height: 13vh;
  border-radius: 8px;
  cursor: pointer;
}

.product-description-v2 {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  margin-top: 0;
  font-family: Plus Jakarta Sans;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.product_controls {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.quantity_controls {
  display: flex;
  align-items: center;
}

.control_button {
  background: #cdcdcd;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
}

.quantity_text {
  font-size: 14px;
  padding: 0 5px;
}

.remove_button {
  background: none;
  border: none;
  font-size: 10px;
  margin-top: 2px;
  cursor: pointer;
  color: red;
}
