/* Skeleton Loader Styles */
.skeleton {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #e0e0e0;
  margin-bottom: 10px;
  border-radius: 8px;
}

.skeleton-discount-badge,
.skeleton-rating-value,
.skeleton-reviews,
.skeleton-name,
.skeleton-description,
.skeleton-price,
.skeleton-original-price {
  background: #e0e0e0;
  margin: 8px 0;
  border-radius: 4px;
}

.skeleton-discount-badge {
  width: 60px;
  height: 20px;
}

.skeleton-rating-value {
  width: 50px;
  height: 16px;
}

.skeleton-reviews {
  width: 70px;
  height: 16px;
}

.skeleton-name {
  width: 200px;
  height: 20px;
}

.skeleton-description {
  width: 250px;
  height: 16px;
}

.skeleton-price,
.skeleton-original-price {
  width: 100px;
  height: 20px;
}
