.product-reviews-card {
  font-family: var(--font-family-Inter);
  flex: 1;
  border-radius: var(--radius-large);
  border: 1px solid var(--border-primary);
  background: var(--surface-primary);
  padding: var(--spacingx3);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: var(--spacingx4);
  max-height: 156px;
}

.product-reviews-card-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  min-width: 176px;
  gap: var(--spacingx2);
}
.product-reviews-card-heading-rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx0-5);
}
.product-reviews-card-heading-rating-text {
  font-family: var(--font-family-inter);

  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--text-primary);
  line-height: var(--line-height-small);

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.product-reviews-card-heading-rating-text.full {
  /* When full text is displayed, remove truncation */
  line-clamp: unset;
  -webkit-line-clamp: unset;
  overflow: visible;
}

.product-reviews-card-heading-rating-text-read-more-button {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--content-secondary);
  line-height: var(--line-height-small);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.product-reviews-card-reviewer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx2);
}

.product-reviews-card-reviewer-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-reviews-card-reviewer-avatar {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-round);
  border: 1px solid var(--black);
  object-fit: cover;
}

.product-reviews-card-reviewer-name-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-reviews-card-reviewer-name {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  line-height: var(--line-height-small);
}

.product-reviews-card-review-date {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  color: var(--text-secondary);
  line-height: var(--line-height-small);
}
