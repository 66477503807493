.search-bar {
  display: flex;
  align-items: center;
  background-color: #356162; /* Dark teal background */
  border-radius: 10px;
  padding: 10px 15px;
  width: 100%;
  margin-left: 8px;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.static-search-bar {
  display: flex;
  align-items: center;
  background-color: #356162; /* Dark teal background */
  border-radius: 10px;
  padding: 10px 15px;
  width: 100%;
  margin-left: 8px;
  cursor: pointer;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.search-bar__icon {
  margin-right: 10px;
  color: #ffffff; /* Icon color */
}

.search-bar__svg {
  width: 20px;
  height: 20px;
}

.search-bar__input {
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffffff; /* Input text color */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.search-bar__input::placeholder {
  color: #d4e2e2; /* Placeholder text color */
  opacity: 0.8;
}

.recommended-items {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 1rem; /* Add padding around the whole list */
}

.recommended-item {
  background-color: #fff; /* White background for each item */
  padding: 1rem;
  border-radius: 8px; /* Rounded corners */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); Light shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.recommended-item:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); Darker shadow on hover */
}

.recommended-item-product-name {
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: bold;
  color: #333; /* Dark text color */
  margin: 0;
}

.recommended-item:hover .recommended-item-product-name {
  color: #007bff; /* Change text color on hover */
}

/* Add responsiveness for smaller screens */
@media (max-width: 600px) {
  .recommended-items {
    padding: 0.5rem;
  }

  .recommended-item {
    padding: 0.8rem; /* Adjust padding on small screens */
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-bar {
    /* width: 20px; */
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
    color: white;
    box-shadow: none;
    background-color: #30565c;
  }

  .search-bar__svg {
    width: 18px;
    height: 18px;
    color: white;
  }

  .search-bar__icon {
    margin-right: 0px;
  }

  .search-bar__input {
    font-size: 12px;
  }
}

/* Fullscreen search modal */
.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.search-modal__header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.search-modal__back {
  background: none;
  border: none;
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
  color: #757575;
}

.search-modal__input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.search-modal__suggestions {
  padding-left: 29px;
}

.search-modal__suggestions p {
  font-size: 14px;
  color: #757575;
}

.search-modal__suggestions ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search-modal__suggestions li {
  margin-bottom: 8px;
  font-size: 14px;
  color: #424242;
  cursor: pointer;
}

.search-modal__suggestions li:hover {
  color: #000;
}
