.products-page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.products-page-filter-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 640px) {
  .products-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 5%;
    font-weight: 700;
    font-size: x-large;
    width: 80%;
    margin-left: 3%;
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  .products-page-sortby-container {
    flex: 1;
  }
  .products-page-filterby-container {
    flex: 1;
  }

  .products-page-dropdown-container {
    width: 100%;
    /* margin-left: 5%;
    margin-right: 5%; */
  }

  .products-page-dropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--Light-Grey, #e3e3e3);
  }

  .products-page-dropdown-sortby-container {
    flex: 1;
    border-width: 0px;
  }

  .products-page-dropdown-filterby-container {
    flex: 1;
    border-width: 0px;
  }

  .products-page-dropdown-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 52px;
    align-items: center;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: white;
    height: 100%;
    border-width: 0px;
  }

  .products-page-dropdown-text {
    text-align: center;
    margin-left: 8px;
    font-family: Plus Jakarta Sans;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .products-page-dropdown-text-heading {
    font-size: 14px;
    font-weight: 600;
  }

  .products-page-dropdown-text-small {
    font-size: small;
  }

  .products-page-filterby-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 641px) {
  .products-page-container-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .products-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: 700;
    font-size: 32px;
    width: 80%;
    margin-left: 3%;
    font-family: "Plus Jakarta Sans", sans-serif;
  }
  .products-page-dropdown-container {
    padding-right: 2%;
    width: 98%;
    display: flex;
    justify-content: flex-end;
  }
  .products-page-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .products-page-dropdown-divider {
    display: none;
  }
  .products-page-dropdown-button {
    min-width: 127px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Light-Grey, #e3e3e3);
    background: #fff;
    margin-left: 10px;
    position: relative;
    padding: 12px 28px 12px 20px;
  }
  .products-page-dropdown-button.active {
    border: 1px solid var(--Brand, #207a9c);
  }
  .products-page-dropdown-button.selectedSort {
    padding: 12px 24px 12px 20px;
  }

  .products-page-dropdown-sortby-container {
    display: flex;
    flex-direction: flex-end;
    align-items: end;
    position: relative;
    justify-content: flex-end;
  }

  .products-page-dropdown-filterby-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    position: relative;
    justify-content: flex-end;
  }

  .products-page-dropdown-text {
    text-align: center;
    margin-left: 8px;
    font-family: "Plus Jakarta Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .products-page-dropdown-text-heading {
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
  }
  .products-page-dropdown-selected-option {
    width: 6px;
    height: 6px;
    border-radius: 1000px;
    background: var(--Brand, #207a9c);
    margin-left: 8px;
  }

  .products-page-filterby-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 100%; /* Position it right below the button */
    width: 360px;
    z-index: 1000;
    margin-top: 5%;
    background: none;
  }
  .products-page-sortby-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 100%; /* Position it right below the button */
    width: 360px;
    z-index: 1000;
    margin-top: 5%;
    background: none;
  }
}

.products-page-products {
  width: 100%;
  height: 400px;
}
