.storefront {
  font-family: var(--font-family-inter);
  background-color: var(--surface-secondary);
}

.storefront-header {
  display: flex;
  align-items: center;
  padding: 10px;
  /* background-color: #f5f5f5; */
}

.storefront-header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.storefront-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.storefront-search-bar {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  height: 40px;
}

.storefront-search-input {
  margin-left: 1.5%;
  margin-bottom: 1%;
  border: none;
  font-size: 14px;
}

.profile-button {
  width: 35px;
  height: 35px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}

/* Store Info */
.store-info {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.store-header {
  display: flex;
  align-items: center;
}

.store-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.store-details {
  flex: 1;
}

.store-name {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1.5%;
  font-weight: 600;
}

.text-truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.store-category-status {
  display: flex;
}

.store-category {
  color: #777;
  font-size: 12px;
}

.store-status {
  padding: 4px 8px;
  background-color: #d4edda;
  color: #155724;
  font-size: 12px;
  border-radius: 4px;
  margin-right: 4%;
}

.share-button {
  font-size: 14px;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
}

/* Store Stats */
.store-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding-top: 3%;
  padding-bottom: 5%;
}

.stat {
  text-align: center;
}

.stat-number {
  padding-top: 3%;
  font-size: 6px;
  font-weight: 600;
}

.stat-label {
  /* padding-top: 3%; */
  font-size: 10px;
  color: #777;
}

/* Free Delivery Section */
.free-delivery {
  /* margin-top: 10px; */
  padding: 5px;
  background-color: #e3f2fd;
  border-radius: 5px;
  text-align: center;
  color: #007bff;
  display: flex;
}

.free-delivery-para {
  text-align: left;
  margin-left: 2%;
  font-size: 12px;
}

/* Tabs */
.tabs {
  display: flex;
  border-top: 1px solid #eee;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

/* Tab Content */
.tab-content {
  padding: 20px;
}

/* Product Section */
.products-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.categories {
  padding: 10px;
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.category-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.category-button.active {
  background-color: #007bff;
  color: white;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.storefront-product-card {
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.product-image-box {
  width: 100%;
  height: fit-content;
}

.storefront-product-image {
  width: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
}

.product-rating {
  position: absolute;
  top: 220px;
}

.product-info {
  padding: 10px;
}

.price {
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.rating {
  color: #ffa500;
}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  /* background-color: #ff4d4d; */
  color: white;
  font-size: 0.8rem;
}

/* About Section */
/* .about-section {
    padding: 20px;
  } */

.review {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.review span {
  display: block;
  margin-top: 5px;
}

/* Footer */
.storefront-footer {
  padding: 20px;
}

.storefront-footer-zoop {
  font-size: 36px;
  font-weight: bold;
  color: #9ca3af;
  /* text color */
  margin: 0;
  text-align: left;
}

.storefront-footer-made-in {
  font-size: 12px;
  color: #9ca3af;
  /* text color */
  margin: 5px 0 20px 0;
  text-align: left;
}

.storefront-footer-divider {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #d1d5db;
  margin: 20px 0;
}
