.shop-products-container {
  margin-top: 1%;
}

.shop-products-title {
  color: #111827;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.shop-product-container {
  border-radius: 0.75rem;
  border: 0.5px solid #f1f1f3;
  background: #fff;
  margin-bottom: 3%;
}

.shop-products-body {
  display: grid;
  grid-template-columns: 37% 61%;
  gap: 2%;
}

.shop-product-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.shop-product-item {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  gap: 5px;
}

.shop-product-item-header,
.shop-product-item-body,
.shop-product-item-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.shop-product-item-header {
  /* justify-content: space-between; */
  /* margin-right: 5%; */
  flex-direction: column;
  place-items: start;
}

.shop-product-title {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  margin: 3% 0 0 0;
}

.shop-product-subtitle {
  color: #38353f;
  font-family: Plus Jakarta Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.shop-product-item-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.shop-product-cost-price {
  color: #938e9e;
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through;
}

.shop-product-selling-price {
  color: #222026;
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  /* margin-right: 0.5rem; */
}

.shop-product-discount {
  color: #1e799c;
  font-family: Plus Jakarta Sans;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  margin: 5% 0 5% 5px;
}

.shop-product-button-container {
  border-radius: 8px;
  border: 1px solid var(--Calypso-600, #1e799c);
  padding: 0.45rem;
  background: var(--Calypso-600, #1e799c);
}

.shop-product-secondary-button-container {
  border-radius: 8px;
  border: 1px solid var(--Calypso-600, #1e799c);
  padding: 0.45rem;
  background: white;
}

.shop-product-image-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.sold-out-strip {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 10;
}

.sold-out-image {
  opacity: 0.5; /* Dim the image to indicate it's sold out */
}

.shop-product-secondary-button-container:disabled {
  background: #b0e5f1;
  border: 1px solid #d5f2f8;
  color: #eefbfd;
}

.shop-product-button-container:disabled {
  background: #b0e5f1;
  border: 1px solid #d5f2f8;
  color: #eefbfd;
}

.shop-product-button-text {
  color: #fff;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 0, 875rem;
  font-style: normal;
  font-weight: 500;
}

.shop-product-secondary-button-text {
  color: #1e799c;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 0, 875rem;
  font-style: normal;
  font-weight: 500;
}

.shop-products-upcoming-products-container {
  background: #fef7ec;
  padding-top: 2%;
  padding-bottom: 2%;
  min-height: 30vh;
  overflow: scroll;
}

.shop-products-upcoming-products-content-container {
  padding: 2% 5% 5% 5%;
}

.shop-upcoming-products-title {
  color: #eb7815;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  margin: 0;
  padding: 0;
}

.shop-products-buy-now-products-container {
  padding-top: 2%;
  padding-bottom: 2%;
  min-height: 30vh;
  overflow: scroll;
}

.shop-products-buy-now-products-content-container {
  padding: 2%;
}

.shop-no-product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  border-radius: 10px;
  padding: 20px;
}

.shop-no-product-title {
  color: rgb(30, 121, 156);
  margin-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.shop-no-product-subtext {
  color: rgb(100, 100, 100);
  margin-top: 5px;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.shop-product-chips {
  display: flex;
  gap: 6px;
  margin-top: 5px;
}

.chip {
  padding: 5px 7px;
  border-radius: 4px;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  display: inline-block;
}

.chip.available {
  background-color: #e8f4e7;
  color: #108006;
}

.chip.on-hold {
  background-color: #fef6e7;
  color: #9c6407;
}

.chip.bought {
  background-color: #e9f2f6;
  color: #1d7596;
}

.shop-product-discount-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shop-product-line {
  height: 16px;
  border: 0.5px solid #938e9e;
}
