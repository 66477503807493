.fullscreen-container {
  position: fixed; /* Fix to the viewport */
  top: 2%;
  bottom: 2%;
  left: 5%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90vw; /* Full viewport width */
  max-height: 90vh; /* Full viewport height */
  z-index: 9999999; /* Ensure it covers other elements */
}

.fullscreen-iframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove default border */
}

/* Media query for screens smaller than 1000px */
@media (max-width: 1000px) {
  .fullscreen-container {
    max-width: 90vw;
    max-height: 90vh;
  }
}
