.cart-list-heading {
  font-size: x-large;
  font-weight: 600;
  /* margin-bottom: 5%; */
}

.cart_subheader_text {
  color: #7b7589;
  font-family: Plus Jakarta Sans;
}

.cart_checkout_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 33%;
}

.cart_checkout_button {
  /* display: flex; */
  align-items: center;
  background: #1e79a9;
  border-radius: 8px;
  padding-top: 0;
  padding: 16px;
  width: 100%;
  color: whitesmoke;
  font-family: "Plus Jakarta Sans", sans-serif; /* Ensure font-family is correctly referenced */
  border: 1px solid aliceblue; /* Specified border with a solid line */
  cursor: pointer;
}

.cart_checkout_text {
  color: #ffff;
  font-size: 15px;
  font-weight: 600;
}

.back-arrow {
  margin-right: 8px;
}
