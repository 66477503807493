.payment-confirmation-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-confirmation-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.payment-confirmation-title-text {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  margin-top: 2%;
}

.payment-confirmation-body-text {
  color: #656565;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 3%;
}

.payment-confirmation-continue-shop-button {
  border-radius: 4px;
  background: #1e799c;
  margin-top: 10%;
}

.payment-confirmation-continue-shop-button-text {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
  letter-spacing: -0.25px;
}

.payment-confirmation-redirect-text {
  color: #656565;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 3%;
}
