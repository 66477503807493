.otp-joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 25px;
}

.otp-joinChatHeading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
  margin-top: 2rem;
  font-family: Plus Jakarta Sans;
}
.otp-joinChatSubHeading {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: grey;
  font-family: Plus Jakarta Sans;
}

.otp-joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: Plus Jakarta Sans;
}
.otp-joinChatContainer input {
  width: 30px;
  height: 40px;
  margin: 7px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}

.otp-joinChatContainerss input {
  width: 90%;
  height: 40px;
  border: 2px solid #edeef2;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  padding-left: 5%;
  font-family: Plus Jakarta Sans;
}

.otp-joinChatContainer button {
  width: 90%;
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  background: #f5f2fc;
  background-color: rgb(245, 242, 252);
  cursor: pointer;
  font-family: Plus Jakarta Sans;
}

.otp-joinChatContainer button:hover {
  background: #1e799c;
  color: white;
}

.otp-joinChatContainerss button:hover {
  background: #1e799c;
  color: white;
}
