.product-details {
  grid-gap: 20px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin: 0 auto;
  padding: 20px;
}

/* Product Details */
.product-details-content-container {
  display: flex;
  flex-direction: column;
  width: calc(100%);
}

.product-details-discount-percent {
  padding: var(--spacingx1);
  background: var(--surface-status);
  font-size: var(--label-small);
  color: var(--content-inverted);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-small);
  width: fit-content;
  border-top-right-radius: var(--radius-small);
  border-bottom-right-radius: var(--radius-small);
}

.product-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-top: var(--spacingx1);
  margin-bottom: var(--spacingx2);
}

.product-details-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacingx2);
}

.product-details-selling-price {
  font-family: var(--font-family-inter);
  font-size: var(--label-xlarge);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xlarge);
  color: var(--content-primary);
}

.product-details-discount-price {
  font-family: var(--font-family-inter);
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-large);
  color: var(--content-inactive);
  text-decoration: line-through;
}

.product-header-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-large);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-large);
  color: var(--content-primary);
}

.product-header-rediv {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-active);

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.product-header-store-name {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  color: var(--content-secondary);
  margin-top: var(--spacingx1);
}

.product-header-store-name-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  color: var(--content-secondary);
  text-decoration: underline;
}

/* Variants */
.product-details-size-box {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacingx2) 0px;
}

.product-details-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacingx2) 0px;
}

.product-details-variant-label {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.product-details-size-selector {
  display: flex;
  align-items: center;
}
.color-option {
  font-family: var(--font-family-inter);
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-action);
  background: var(--surface-secondary-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* padding: var(--spacingx2) var(--spacingx3); */
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
  height: 30px;
  width: 30px;
}

.size-option {
  font-family: var(--font-family-inter);
  border-radius: var(--radius-medium);
  border: 1px solid var(--border-action);
  background: var(--surface-secondary-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: var(--spacingx2) var(--spacingx3);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}
/* Product Details Actions */
.product-detail-action-cta-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacingx3);
  padding-top: var(--spacingx2);
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
  /* Space between the buttons */
}

.product-detail-buy-now {
  font-family: var(--font-family-inter);
  background-color: var(--surface-inverted);
  color: var(--content-inverted);
  border: none;
  padding: var(--spacingx4) var(--spacingx8);
  border-radius: var(--radius-xxlarge);
  cursor: pointer;
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  flex: 1;
  transition: all 0.2s ease;
}

.product-detail-action-cta-container-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacingx2);
}

.product-detail-add-cart {
  width: calc(50% - var(--spacingx2));
  border-radius: var(--radius-xxlarge);
  border: 1px solid var(--border-action);
  background: var(--surface-primary);
  color: var(--content-primary);
  cursor: pointer;
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
}

.product-detail-bargain {
  width: calc(50% - var(--spacingx2));
  background: var(--surface-action2);
  color: var(--content-primary);
  border: none;
  padding: var(--spacingx4) var(--spacingx8);
  border-radius: var(--radius-xxlarge);
  cursor: pointer;
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
}

.product-details-buyer-protection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacingx3) var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-buyer-protection-container-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacingx2);
  padding: var(--spacingx4) var(--spacingx3);
  border-radius: var(--radius-medium);
  background: var(--content-inverted);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-buyer-protection-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacingx2);
}

.product-details-buyer-protection-left-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-buyer-protection-left-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.product-details-buyer-protection-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-drop-down-container-content {
  padding: var(--spacingx2) var(--spacingx3) 0px var(--spacingx3);
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: var(--content-primary);
}

.product-details-buyer-protection-container-content-learn-more {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  color: var(--content-active);
  cursor: pointer;
}

.product-details-product-details-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: var(--spacingx2) 0px;
  width: 100%;
}

.product-details-product-details-container-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-product-details-container-dropedown {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.product-details-product-details-container-dropedown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacingx4) var(--spacingx4);
}

.product-details-product-details-container-dropedown-header-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-secondary);
}

.product-details-drop-down-container-content-key-details {
  background: var(--content-inverted);
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: var(--content-primary);
  padding: var(--spacingx3) var(--spacingx4);
  display: flex;
  flex-direction: column;
  gap: var(--spacingx2);
}

.product-details-drop-down-container-content-key-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacingx2);
}

.product-details-drop-down-container-content-key-details-key {
  font-weight: var(--font-weight-semibold);
  color: var(--content-secondary);
}

.product-details-drop-down-container-content-product {
  padding: 0px var(--spacingx3);
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: var(--content-primary);
  padding: var(--spacingx3) var(--spacingx4);
  background: var(--content-inverted);
}

.product-details-seller-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--spacingx2);
  padding-bottom: var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
  padding-left: var(--spacingx3);
  padding-right: var(--spacingx3);
}

.product-details-seller-details-container-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
  padding: var(--spacingx2) 0px;
}

.product-details-seller-details-container-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacingx3);
}

.product-details-seller-details-container-content-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacingx2);
}

.product-details-seller-details-container-content-left-avatar {
  width: 48px;
  height: 48px;
  border-radius: var(--radius-round);
  border: 1px solid var(--border-primary);
  object-fit: cover;
}

.product-details-seller-details-container-content-left-name-ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.product-details-seller-details-container-content-left-name {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.product-details-seller-details-container-content-left-name-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-secondary);
}

.product-details-seller-details-container-content-right-button {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  background: var(--surface-primary);
  border: 1px solid var(--border-action);
  cursor: pointer;
}

.product-details-policy-container {
  display: flex;
  background-color: var(--surface-secondary);
  flex-direction: column;
  gap: var(--spacingx1-6);
  padding-top: var(--spacingx1-6);
}

.product-details-policy-container-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacingx4) var(--spacingx3);
  background: var(--surface-primary);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-policy-container-item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacingx3);
}

.product-details-policy-container-item-left-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-policy-container-item-left-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.product-details-policy-container-item-right {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-details-review-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacingx2);
  padding: 0px var(--spacingx3) var(--spacingx4) var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-review-container-title {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
  padding: var(--spacingx2) 0px;
}

.product-details-review-rating-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacingx4);
}

.product-details-review-rating-card-heading {
  display: flex;
  align-items: center;
  justify-content: start;
}

.product-details-review-rating-card-heading-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--spacingx1);
}

.product-details-review-rating-card-heading-rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.product-details-review-rating-card-heading-rating-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
}

.product-details-review-rating-card-heading-rating-separator {
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
  padding-left: var(--spacingx1);
  padding-right: var(--spacingx1);
}

.product-details-review-rating-card-heading-summary {
  font-size: var(--label-large);
  font-weight: var(--font-weight-semibold);
  color: var(--content-primary);
  line-height: var(--line-height-large);
}

.product-details-reviews-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* Keep items in a single row (no wrapping) */
  overflow-x: auto; /* Horizontal scroll if needed */
  gap: var(--spacingx3);
  width: 100%;

  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
}

.product-details-review-show-all-reviews-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.product-details-review-show-all-reviews-button {
  font-family: var(--font-family-inter);
  color: var(--content-primary);
  padding: var(--spacingx2) var(--spacingx3);
  border-radius: var(--radius-medium);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-medium);
  font-style: normal;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-action);
  box-shadow: none;
  min-height: 44px;
}

.product-details-review-no-reviews-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--content-primary);
}

.product-details-policy-container-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.product-details-store-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.product-details-seller-details-content {
  margin-left: 10px;
}

.seller-details-1 {
  font-weight: 600;
  font-size: 16px;
}

/* Product Image*/
.product-details-product-image {
  width: 354px;
  height: 504px;
  /* background-color: #e0e0e0; */
  border-radius: 8px;
}

/* Carousel */
.product-img-container {
  text-align: center;
  max-width: 800px;
  position: relative;
}

.productImage {
  max-width: 100%;
  height: auto;
}

.product-img-pagination-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacingx3);
}

.product-img-pagination-container-text {
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: var(--content-primary);
  background-color: var(--surface-action1);
  padding: var(--spacingx1) var(--spacingx2);
  border-radius: var(--radius-small);
  color: var(--content-inverted);
}

.product-img-container-rating {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--spacingx3) var(--spacingx3) var(--spacingx4) var(--spacingx3);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.product-img-container-rating-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--radius-small);
  background: var(--surface-secondary);
  padding: var(--spacingx1) var(--spacingx2);
  font-family: var(--font-family-inter);
  font-size: var(--label-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-small);
  color: var(--content-primary);
}

.product-img-container-right-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--spacingx3);
}

.product-img-container-right-container-share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--surface-action1);
}

.product-details-meta-data-container {
  margin: 15px;
}

.product-details-meta-data-key {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  color: #1f2937;
}

.product-details-meta-data-value {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  color: #1f2937;
}

.product-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px var(--spacingx3);
  width: calc(100% - 2 * var(--spacingx3));
}

.product-details-review-single-images img:nth-child(1) {
  height: 80%;
  width: 95%;
  max-width: 800px;
}

/* .product-details-review-images img:nth-child(n + 2) {
  flex-basis: calc(50% - 8px);
} */

.product-header-left {
  flex-grow: 1;
}

.product-details-size-selector-list {
  display: flex;
  flex-wrap: wrap;
}

/* Accordion */
.product-details-accordion-container {
  margin-top: 20px;
}

.product-details-title-container {
  display: flex;
}

.product-details-accordion-container-item {
  border-radius: 4px;
  padding: 10px;
}

.product-details-return-policy {
  font-size: 14px;
  color: #6b7280;
}

.product-details-link {
  color: #1e799c;
  text-decoration: none;
}

.details-text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #1f2937;
}

.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10%;
}

.feature {
  text-align: center;
  flex-basis: 30%;
}

.feature-icon {
  width: 48px;
  height: 48px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.feature-icon svg {
  width: 24px;
  height: 24px;
  color: #666666;
}

.feature p {
  margin: 5px 0;
  color: #666666;
  font-size: 14px;
}

.accordion .accordion-item:first-child .accordion-content {
  display: block;
  max-height: none;
}

.accordion .accordion-item:first-child .accordion-icon {
  transform: rotate(180deg);
}

.accordion .accordion-item:first-child .accordion-button {
  font-weight: bold;
}

.product-details-review-user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #e5e7eb;
  margin-right: 0.5rem;
}

.product-details-user-reviews {
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem;
}

.product-details-review-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  flex-direction: column;
}

.product-details-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.product-details-review-user-details {
  display: flex;
  align-items: center;
}

.product-details-review-content {
  flex: 1;
}

/* .product-details-review-header {
  display: flex;
  margin-bottom: 0.25rem;
  flex-direction: column;
  gap: 10px;
} */

.product-details-review-name {
  font-weight: 600;
  font-size: 1.125rem;
  margin-right: 0.5rem;
}

.product-details-review-date {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.3rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
}

.product-details-review-text {
  color: #9ca3af;
}

.product-detail {
  cursor: pointer;
  background: transparent;
  padding: 14px 20px 14px 20px;
  margin-bottom: 1rem;
  border: 1px;
  border-radius: 4px;
  background: #1e799c;
  width: 100%;
  margin-left: 10px;
}

/* Container */
.product-detials-ratings-reviews-container {
  padding: 16px;
  font-family: Arial, sans-serif;
}

/* Overall Rating */
.product-details-overall-rating {
  font-size: 2rem;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-details-star-count {
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-weight: 600;
}

.product-details-total-reviews {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  color: #1f2937;
  margin-left: 3px;
}

/* Image Row */
.product-details-review-images {
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
  margin-bottom: 24px;
}

.product-details-review-img-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.product-details-review-image {
  width: 64px;
  height: 64px;
  background-color: #e0e0e0;
  border-radius: 8px;
}

/* Reviews Section */
.product-details-reviews-section {
  margin-bottom: 10%;
}

/* See All Reviews Button */
.product-details-see-all-reviews {
  text-align: center;
}

.product-details-see-all-reviews button {
  padding: 8px 16px;
  font-size: 1rem;
  background-color: transparent;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .product-details {
    max-width: 100%;
    font-family: var(--font-family-inter);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    grid-gap: 0px;
    margin: 0px;
    padding: 0px;
  }

  .product-price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-top: var(--spacingx1);
    margin-bottom: var(--spacingx2);
    gap: var(--spacingx2);
  }

  .product-details-seller-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-detail-container {
    flex-direction: column;
    align-items: center;
  }
}
